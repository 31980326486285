import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";

export const Estadisticas = () => {
  return (
    <div className="container-fluid contarinerGeneralUserHomejs">
      <div className="container asklnduiashndaijsnxansxsaas">
        <i
          className="fa-solid fa-circle-exclamation"
          style={{ fontSize: "100px" }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            color: "rgba(128, 128, 128, 0.697)",
          }}
        >
          <h1 style={{ color: "rgba(128, 128, 128, 0.697)" }}>
            ¿Listo para Controlar tu Negocio?
          </h1>
          <p style={{ color: "rgba(128, 128, 128, 0.697)" }}>
            No tu te adaptas al Sistema, el Sistema se adapta a ti y a tus
            necesidades, ahorrando tiempo y dinero
          </p>
        </div>
      </div>
    </div>
  );
};
