import React, { useContext, useEffect, useState, useRef } from "react";
import "../../styles/contactanos.css";
import { Helmet } from "react-helmet-async";

export const Contactanos = () => {
  const [formData, setFormData] = useState({
    name: "",
    empresa: "",
    email: "",
    descripcion: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Gracias por compartir tu idea con nosotros!</p>,
      html: (
        <p>Te enviaremos un correo electrónico muy pronto con una respuesta</p>
      ),
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Algo sal mal, por favor vuelve a intentarlo</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Enviar el correo al backend
      await axios.post(process.env.BACKEND_URL + "/api/contacto", formData);

      mostrarAlerta1();
      // Limpiar el formulario después de enviar los datos
      setFormData({
        name: "",
        empresa: "",
        email: "",
        descripcion: "",
      });
    } catch (error) {
      mostrarAlerta2();
    }
  };

  return (
    <div
      className="as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd"
      style={{ minHeight: "100vh" }}
    >
      <Helmet>
        <title>Contáctanos - Sasha Tech</title>
        <meta
          name="description"
          content="Estamos aquí para ayudarte. Contáctanos para obtener más información sobre nuestros servicios de marketing digital y desarrollo web."
        />
        <link rel="canonical" href="https://www.sashatech.com/contactanos" />
        <meta property="og:title" content="Contáctanos - Sasha Tech" />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/sitemap.xml"
        />

        <meta
          property="og:description"
          content="Estamos aquí para ayudarte. Contáctanos para obtener más información sobre nuestros servicios de marketing digital y desarrollo web."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dirpdlfbu/image/upload/v1728129163/SashaTech/itlpjnnksxpp6tyvj4bb.png"
        />
        <link rel="icon" href="ellipse.png" type="image/x-icon" />
        <meta property="og:url" content="https://www.sashatech.com/login" />
        <meta property="og:type" content="website" />
        <meta name="theme-color" content="#041A22" />
      </Helmet>
      <div className="as54s98df7er98g7erw984cwe">
        <h1 style={{ color: "white", marginTop: "40px" }}>Contáctanos</h1>
      </div>
      <div
        className="container as65d489f7889we7f89ewwe"
        style={{ paddingTop: "70px" }}
      >
        <div className="s56d4a9s8d7we987vw98v7e894ewe">
          <h1 style={{ color: "black", marginBottom: "20px" }}>
            Nuestra Información de Contacto
          </h1>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            Estás cada vez más cerca de cumplir tus metas llevando un total
            control de tu negocio
          </p>
          <div
            className="sdf65s4d8f9s79f8w7e8ht4rwe"
            style={{ marginTop: "20px" }}
          >
            <div className="sdferg4er98few498f4we">
              <i className="fa-solid fa-phone-volume sd564fsd98f79d8f798hh7te89rwe"></i>
            </div>
            <div className="sdferg4er98few498f4we2">
              <h4 style={{ color: "black" }}>Números de Teléfono:</h4>
              <p style={{ color: "black" }}>
                Movil y Whatsapp: +58 4242086513 // +34 624443813
              </p>
            </div>
          </div>
          <div
            className="sdf65s4d8f9s79f8w7e8ht4rwe"
            style={{ marginTop: "40px" }}
          >
            <div className="sdferg4er98few498f4we">
              <i className="fa-regular fa-envelope sd564fsd98f79d8f798hh7te89rwe"></i>
            </div>
            <div className="sdferg4er98few498f4we2">
              <h4 style={{ color: "black" }}>Correo Electrónico:</h4>
              <p style={{ color: "black" }}>contacto@sashatechve.com</p>
            </div>
          </div>
          <div
            className="sdf65s4d8f9s79f8w7e8ht4rwe"
            style={{ marginTop: "40px" }}
          >
            <div className="sdferg4er98few498f4we">
              <i className="fa-solid fa-location-dot sd564fsd98f79d8f798hh7te89rwe"></i>
            </div>
            <div className="sdferg4er98few498f4we2">
              <h4 style={{ color: "black" }}>Somos Agencia Online:</h4>
              <p style={{ color: "black" }}>
                Lo que nos da facilidad a la vez de tener servicio 24/7
              </p>
            </div>
          </div>
        </div>
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe"
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d316367.3313567729!2d-67.05178076648639!3d10.464998170988874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a58adcd824807%3A0x93dd2eae0a998483!2sCaracas%2C%20Distrito%20Capital%2C%20Venezuela!5e0!3m2!1ses!2ses!4v1724953172187!5m2!1ses!2ses"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="d56fs4df98ew7fwemaps"
          />
        </div>
      </div>
      <div
        className="d-flex justify-content-center w-100 sdfs4df9e4g98r4t8j9tyb9erf48w9e7fd9qe4we8"
        style={{ marginTop: "70px" }}
      >
        <div
          className="d-flex justify-content-center w-100"
          style={{
            flexDirection: "column",
            alignContent: "center",
            width: "100%",
            alignItems: "center",
            marginBottom: "70px",
            padding: "10px",
          }}
        >
          <div className="tittle-80 w-100" style={{ maxWidth: "900px" }}>
            <h2
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "70px",
              }}
            >
              ¿Hablamos?, Cuentanos sobre tu proyecto
            </h2>
            <p
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "40px",
              }}
            >
              En Sasha Tech, estamos encantados de conocer más sobre ti y tu
              proyecto. Antes de que nos contactes, nos gustaría que nos cuentes
              un poco sobre lo que tienes en mente para poder ofrecerte la mejor
              solución posible. Para hacerlo más fácil y directo, hemos
              preparado el siguiente mini formulario para que puedas compartir
              los detalles más importantes con nosotros.
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin s6d54fsd89f7sd98f4we98f7wr89 w-100"
          >
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
              />
              <label htmlFor="name" style={{ backgroundColor: "#F6F6F4" }}>
                Nombre
              </label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" style={{ backgroundColor: "#F6F6F4" }}>
                Correo electrónico
              </label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={formData.empresa}
                onChange={handleChange}
                required
              />
              <label htmlFor="empresa" style={{ backgroundColor: "#F6F6F4" }}>
                Empresa
              </label>
            </div>
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="text"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                required
                style={{ minHeight: "200px" }}
              />
              <label
                htmlFor="descripcion"
                style={{ backgroundColor: "#F6F6F4" }}
              >
                Describe brevemente de que se trata el proyecto...
              </label>
            </div>
            <button className="buttonCargadeDatosDeLogin" type="submit">
              Enviar Datos <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
