import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const Reportes = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    company_name: "",
    company_rif: "",
    company_intern_code: "",
  });

  const blogsData = [
    {
      id: 2,
      fecha: "15 de Marzo, 2024",
      ubicacion: "Castellón, España",
      titulo: "Reporte General entre Fechas",
      descripcion:
        "Permite visualizar todas las transacciones realizadas dentro de un rango de fechas definido por el cliente. Es ideal para obtener una visión global de la actividad financiera en un periodo específico.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426169/1/graham/son1nhbp2jdbf6w8wvzz.jpg')",
    },
    {
      id: 3,
      fecha: "5 de Abril, 2024",
      ubicacion: "Castellón, España",
      titulo: "Cierre Mensual",
      descripcion:
        "Un resumen detallado del balance financiero al final de cada mes, con ingresos y egresos totales. Facilita el análisis del rendimiento mensual y la preparación para la contabilidad.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426170/1/graham/i8x21khjq48jr9rd5v6n.jpg')",
    },
    {
      id: 4,
      fecha: "10 de Mayo, 2024",
      ubicacion: "Castellón, España",
      titulo: "Reporte de Clientes",
      descripcion:
        "Detalle de todas las transacciones relacionadas con los clientes, con información sobre sus compras y pagos. Ideal para analizar la frecuencia y volumen de las ventas por cliente.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426173/1/graham/lltxnbqtmsyjhhms2fds.jpg')",
    },
    {
      id: 5,
      fecha: "25 de Junio, 2024",
      ubicacion: "Castellón, España",
      titulo: "Reporte de Categorías",
      descripcion:
        "Muestra las transacciones agrupadas por categorías, como ventas, servicios, gastos operativos, entre otros. Ayuda a identificar qué categorías generan más ingresos o costos.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426174/1/graham/jby3c6qzjezdgzwepq4s.jpg')",
    },
    {
      id: 6,
      fecha: "8 de Agosto, 2024",
      ubicacion: "Castellón, España",
      titulo: "Reporte de Métodos de Pago",
      descripcion:
        "Ofrece un análisis detallado de las transacciones según el método de pago, como efectivo, tarjeta o transferencia. Útil para saber qué métodos son más utilizados por los clientes.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426177/1/graham/dcynkpab6wmgbudl8ql1.jpg')",
    },
    {
      id: 7,
      fecha: "14 de Septiembre, 2024",
      ubicacion: "Castellón, España",
      titulo: "Reporte de Transacciones Pendientes",
      descripcion:
        "Este reporte detalla las transacciones que aún no han sido liquidadas o completadas, permitiendo al cliente hacer un seguimiento de pagos o cobros pendientes.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426178/1/graham/etu28jevy51twgq49aaa.jpg')",
    },
    {
      id: 8,
      fecha: "3 de Octubre, 2024",
      ubicacion: "Castellón, España",
      titulo: "Reporte de Balance Semanal",
      descripcion:
        "Proporciona un balance financiero semanal con ingresos y egresos registrados cada semana, permitiendo analizar las tendencias y variaciones a corto plazo.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426403/1/graham/zf2sxc95wrwsmhfhgwir.jpg')",
    },
    {
      id: 1,
      fecha: "20 de Febrero, 2024",
      ubicacion: "Castellón, España",
      titulo: "Reporte General Diario",
      descripcion:
        "Este reporte muestra todos los ingresos y egresos registrados en un día específico, elegido por el cliente. Organizado por tipo de transacción, se incluyen detalles como la fecha, descripción, monto y método de pago.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1727426167/1/graham/e7kxzos4bpapzp0fyyhl.jpg')",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerCompany",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        company_name: "",
        company_rif: "",
        company_intern_code: "",
      });

      MySwal.fire({
        title: "Empresa Registrada",
        text: "La empresa ha sido registrada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la empresa.";

      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchCompanies = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getAllCompanies",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Empresas Registradas",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
            <p><strong>Nombre de Empresa:</strong> ${item.company_name}</p>
            <p><strong>RIF de Empresa:</strong> ${item.company_rif}</p>
            <p><strong>Código Interno:</strong> ${item.company_intern_code}</p>
            <button class="btn btn-secondary" style="background: #a9a9a9; border: none" id="viewCPSDetails-${item.id}">Ver Detalles de CPS</button>
          </div>
        `
          )
          .join("");

        MySwal.fire({
          title: "Empresas Registradas",
          html: `
            <div id="companies-list">${content}</div>
          `,
          confirmButtonText: "Cerrar",
        });

        // Attach event listeners for the dynamically created buttons
        data.forEach((item) => {
          document
            .getElementById(`viewCPSDetails-${item.id}`)
            .addEventListener("click", () => viewCPSDetails(item));
        });
      }
    } catch (error) {
      console.error("Error al obtener empresas registradas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener empresas registradas. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const viewCPSDetails = (company) => {
    const cpsDetails = company.cps_data
      .map(
        (cps) => `
        <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; justify-content: center; align-items: center; flex-direction: column; display: flex; background-color: ${cps.cps_status === "ACTIVO" ? "#0080001c" : "#ff00001a"}">
          <p><strong>Empresa:</strong> ${cps.cps_company_name}</p>
          <p><strong>Ruta:</strong> ${cps.cps_route}</p>
          <p><strong>Destino Final:</strong> ${cps.cps_final_destination}</p>
        </div>
        `
      )
      .join("");

    MySwal.fire({
      title: `Detalles de CPS para ${company.company_name}`,
      html: `
        <div>${cpsDetails}</div>
      `,
      confirmButtonText: "Cerrar",
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div
        className="container-fluid contaosijdasd-qw89d77asd2registromovimiento"
        style={{
          backgroundImage:
            "url(https://res.cloudinary.com/dirpdlfbu/image/upload/v1727682027/puogz7ufhmbqbwwwcvlq.jpg)",
        }}
      ></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-11vh" }}
      >
        Un Nuevo Reporte te espera!
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <div className="d-flex blogs-container mt-5 mb-6">
          {/* Última noticia en una columna separada */}
          {blogsData.slice(-1).map((blog) => (
            <div className="wedw8efe9c4ec9e4c w-100 d-flex justify-content-center">
              <div key={blog.id} className="center-blog">
                {/* <div
                  className="blog-imageprincipal"
                  style={{
                    backgroundImage: blog.imagen,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "15px",
                    alignItems: "end",
                    display: "flex",
                  }}
                ></div> */}
                <div className="blog-details">
                  <h2 style={{ color: "black" }}>{blog.titulo}</h2>
                  <p className="ny-tbrt84ve94h89ytb4v89er">
                    {blog.descripcion.slice(0, 300)}...
                  </p>
                  <Link
                    to={`/blog/detalle/${blog.id}`}
                    className="btn btn-ds98sd4f8d4-sd594csdc489d"
                  >
                    Acceder al Reporte{" "}
                    <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          {/* Renderizar la última noticia en una columna separada */}
          {blogsData.slice(0, -1).map((blog) => (
            <div key={blog.id} className="blog-card">
              {/* <div
                className="blog-image"
                style={{
                  backgroundImage: blog.imagen,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "15px 0px 15px 0px",
                  alignItems: "end",
                  display: "flex",
                  width: "100%",
                }}
              ></div> */}
              <div className="blog-details">
                <h2 className="s-wd5we984fw9e84t98rb4v">{blog.titulo}</h2>
                <p className="ny-tbrt84ve94h89ytb4v89er">
                  {blog.descripcion.slice(0, 100)}...
                </p>
                <Link
                  to={`/blog/detalle/${blog.id}`}
                  className="btn btn-ds98sd4f8d4-sd594csdc489d"
                >
                  Acceder al Reporte{" "}
                  <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
