// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
}

.arrow {
  position: absolute;
  top: 50%;
  font-size: 2em;
  color: white;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.115);
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/front/styles/home3.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,cAAc;EACd,YAAY;EACZ,eAAe;EACf,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,gCAAgC;;EAEhC;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":[".carousel-container {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 80vh;\r\n  overflow: hidden;\r\n}\r\n\r\n.carousel-image {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: relative;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-position: center;\r\n  transition: background-image 0.5s ease-in-out;\r\n}\r\n\r\n.arrow {\r\n  position: absolute;\r\n  top: 50%;\r\n  font-size: 2em;\r\n  color: white;\r\n  cursor: pointer;\r\n  background: rgba(0, 0, 0, 0.115);\r\n  padding: 10px;\r\n  border-radius: 50%;\r\n  transition: background 0.3s ease;\r\n\r\n  &:hover {\r\n    background: rgba(0, 0, 0, 0.8);\r\n  }\r\n}\r\n\r\n.left {\r\n  left: 10px;\r\n}\r\n\r\n.right {\r\n  right: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
