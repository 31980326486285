import React from "react";

const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
      <img
        style={{ width: "100px", position: "absolute" }}
        src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1702159106/logo/y5gr2egwiruemjwvbqzo.png"
      />
    </div>
  );
};

export default LoadingOverlay;
