import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { createRoot } from "react-dom/client";

const MySwal = withReactContent(Swal);

const estatusColors = {
  ACTIVO: "#d4edda", // Verde claro
  ANULADO: "#f8d7da", // Rojo claro
  "EN PISTA": "#fff3cd", // Amarillo claro
  CARGANDO: "#d1ecf1", // Azul claro
  SALIENDO: "#fefefe", // Blanco
  SALIO: "#e2e3e5", // Gris claro
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    border: "1px solid #ccc",
  },
  text: {
    fontSize: 12,
  },
});

const PDFDocument = ({ records }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {records.map((item) => (
        <View
          key={item.id}
          style={[
            styles.section,
            { backgroundColor: estatusColors[item.estatus] || "#ffffff" },
          ]}
        >
          <Text style={styles.text}>
            <Text style={{ fontWeight: "bold" }}>Empresa:</Text> {item.empresa}
          </Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: "bold" }}>Ruta:</Text> {item.ruta}
          </Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: "bold" }}>Destino Final:</Text>{" "}
            {item.destino_final}
          </Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: "bold" }}>Hora de Salida:</Text>{" "}
            {item.hora_salida}
          </Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: "bold" }}>Anden:</Text> {item.anden}
          </Text>
          <Text style={styles.text}>
            <Text style={{ fontWeight: "bold" }}>Estatus:</Text> {item.estatus}
          </Text>
        </View>
      ))}
    </Page>
  </Document>
);

export const Activacion_pista = () => {
  const { store } = useContext(Context);
  const today = new Date().toISOString().split("T")[0]; // Fecha de hoy en formato "YYYY-MM-DD"
  const [formData, setFormData] = useState({
    fecha_viaje: today,
    empresa: "",
    taquilla: "",
    rif: "",
    parada: "",
    ruta: "",
    destino_final: "",
    referencia: "",
    hora_salida: "",
    numero_unidad: "",
    placa: "",
    anden: "",
    link_logo_empresa: "",
  });
  const [companies, setCompanies] = useState([]);
  const [cpsData, setCpsData] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.empresa ||
      !formData.rif ||
      !formData.parada ||
      !formData.ruta ||
      !formData.destino_final ||
      !formData.referencia ||
      !formData.hora_salida
    ) {
      MySwal.fire({
        title: "Error",
        text: "Por favor, complete todos los campos requeridos.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerActivacionPista",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        fecha_viaje: today,
        empresa: "",
        taquilla: "",
        rif: "",
        parada: "",
        ruta: "",
        destino_final: "",
        referencia: "",
        hora_salida: "",
        numero_unidad: "",
        placa: "",
        anden: "",
        link_logo_empresa: "",
      });

      MySwal.fire({
        title: "Activación de Pista Registrada",
        text: "La activación de pista ha sido registrada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      fetchTodayRecords();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la activación de pista.";

      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "empresa") {
      const selectedCompany = companies.find(
        (company) => company.company_name === value
      );
      setFormData((prevData) => ({
        ...prevData,
        rif: selectedCompany ? selectedCompany.company_rif : "",
        taquilla: selectedCompany ? selectedCompany.company_intern_code : "",
        link_logo_empresa: selectedCompany ? selectedCompany.link_logo : "",
        parada: "",
        ruta: "",
        destino_final: "",
        referencia: "",
      }));
      setCpsData(selectedCompany ? selectedCompany.cps_data : []);
      setRutas([]);
    }

    if (name === "parada") {
      const filteredCps = cpsData.filter((cps) => cps.cps_parada === value);
      const uniqueRutas = [...new Set(filteredCps.map((cps) => cps.cps_route))];
      setRutas(uniqueRutas);
      setFormData((prevData) => ({
        ...prevData,
        ruta: "",
        destino_final: "",
        referencia: "",
      }));
    }

    if (name === "ruta") {
      const selectedCps = cpsData.find((cps) => cps.cps_route === value);
      setFormData((prevData) => ({
        ...prevData,
        destino_final: selectedCps ? selectedCps.cps_final_destination : "",
        referencia: selectedCps ? selectedCps.cps_shift_time : "",
      }));
    }
  };

  const fetchTodayRecords = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando registros de hoy...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getTodayActivacionPista",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;
      setRecords(data);

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Registros de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron registros.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        showRecordsPopup(data);
      }
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener registros de hoy. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const showRecordsPopup = (data) => {
    const filteredRecords = data.filter((record) => {
      return (
        record.empresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.ruta.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.destino_final.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.hora_salida.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.anden.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.estatus.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    const content = filteredRecords
      .map(
        (item) => `
      <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; background-color: ${estatusColors[item.estatus] || "#ffffff"};">
        <p><strong>Empresa:</strong> ${item.empresa}</p>
        <p><strong>Ruta:</strong> ${item.ruta}</p>
        <p><strong>Destino Final:</strong> ${item.destino_final}</p>
        <p><strong>Hora de Salida:</strong> ${item.hora_salida}</p>
        <p><strong>Anden:</strong> ${item.anden}</p>
        <p><strong>Estatus:</strong> ${item.estatus}</p>
        <div style="display: flex; justify-content: space-around;">
          <button class="btn btn-info" data-id="${item.id}" data-action="showRouteDetails">
            <i class="fa fa-info-circle"></i>
          </button>
          <button class="btn btn-warning" data-id="${item.id}" data-action="changeStatus" data-status="${item.estatus}">
            <i class="fa fa-exchange-alt"></i>
          </button>
        </div>
      </div>
    `
      )
      .join("");

    MySwal.fire({
      title: "Registros de Hoy",
      html: `
        <input type="text" id="searchRecords" class="swal2-input" placeholder="Buscar..." style="margin-bottom: 20px;">
        <div id="today-records-list">${content}</div>
      `,
      showConfirmButton: false,
      didRender: () => {
        const searchInput = document.getElementById("searchRecords");
        searchInput.addEventListener("input", (e) => {
          setSearchTerm(e.target.value);
          updateRecordsList(data, e.target.value);
        });

        document
          .querySelectorAll('button[data-action="showRouteDetails"]')
          .forEach((button) =>
            button.addEventListener("click", (e) => {
              const id = e.currentTarget.getAttribute("data-id");
              showRouteDetails(id);
            })
          );
        document
          .querySelectorAll('button[data-action="changeStatus"]')
          .forEach((button) =>
            button.addEventListener("click", (e) => {
              const id = e.currentTarget.getAttribute("data-id");
              const status = e.currentTarget.getAttribute("data-status");
              changeStatus(id, status);
            })
          );
      },
      footer: `
        <div style="display: flex; justify-content: space-between;">
          <button class="swal2-cancel swal2-styled" style="background-color: #d33;">Cerrar</button>
          <div id="pdf-download-link"></div>
        </div>
      `,
      didOpen: () => {
        const closeButton = document.querySelector(".swal2-cancel");
        closeButton.addEventListener("click", () => {
          MySwal.close();
        });

        const pdfDownloadLinkContainer =
          document.getElementById("pdf-download-link");
        const root = createRoot(pdfDownloadLinkContainer);
        root.render(
          <PDFDownloadLink
            document={<PDFDocument records={filteredRecords} />}
            fileName="activaciones_rutas.pdf"
            className="btn btn-primary"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Cargando PDF..." : <i className="fa fa-file-pdf" />
            }
          </PDFDownloadLink>
        );
      },
    });
  };

  const updateRecordsList = (data, searchTerm) => {
    const filteredRecords = data.filter((record) => {
      return (
        record.empresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.ruta.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.destino_final.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.hora_salida.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.anden.toLowerCase().includes(searchTerm.toLowerCase()) ||
        record.estatus.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    const content = filteredRecords
      .map(
        (item) => `
      <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; background-color: ${estatusColors[item.estatus] || "#ffffff"};">
        <p><strong>Empresa:</strong> ${item.empresa}</p>
        <p><strong>Ruta:</strong> ${item.ruta}</p>
        <p><strong>Destino Final:</strong> ${item.destino_final}</p>
        <p><strong>Hora de Salida:</strong> ${item.hora_salida}</p>
        <p><strong>Anden:</strong> ${item.anden}</p>
        <p><strong>Estatus:</strong> ${item.estatus}</p>
        <div style="display: flex; justify-content: space-around;">
          <button class="btn btn-info" data-id="${item.id}" data-action="showRouteDetails">
            <i class="fa fa-info-circle"></i>
          </button>
          <button class="btn btn-warning" data-id="${item.id}" data-action="changeStatus" data-status="${item.estatus}">
            <i class="fa fa-exchange-alt"></i>
          </button>
        </div>
      </div>
    `
      )
      .join("");

    document.getElementById("today-records-list").innerHTML = content;

    document
      .querySelectorAll('button[data-action="showRouteDetails"]')
      .forEach((button) =>
        button.addEventListener("click", (e) => {
          const id = e.currentTarget.getAttribute("data-id");
          showRouteDetails(id);
        })
      );
    document
      .querySelectorAll('button[data-action="changeStatus"]')
      .forEach((button) =>
        button.addEventListener("click", (e) => {
          const id = e.currentTarget.getAttribute("data-id");
          const status = e.currentTarget.getAttribute("data-status");
          changeStatus(id, status);
        })
      );
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getAllCompaniesProgramadorRL",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;
      setCompanies(data);
      MySwal.close(); // Close the loading dialog after fetching the companies
      selectCompanyDialog(data); // Show the company selection dialog
    } catch (error) {
      console.error("Error al obtener empresas registradas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener empresas registradas. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const selectCompany = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando empresas...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
        fetchCompanies();
      },
    });
  };

  const selectCompanyDialog = (companies) => {
    const companyOptions = companies.map((company, index) => ({
      id: index,
      text: `<strong>Nombre:</strong> ${company.company_name}<br/><strong>RIF:</strong> ${company.company_rif}`,
      value: company,
    }));

    const inputValue = { value: "" };
    MySwal.fire({
      title: "Seleccionar Empresa",
      html: `
        <input type="text" id="searchCompany" class="swal2-input" placeholder="Buscar Empresa">
        ${companyOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="company-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchCompany");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              empresa: companyOptions[id].value.company_name,
              rif: companyOptions[id].value.company_rif,
              taquilla: companyOptions[id].value.company_intern_code,
              link_logo_empresa: companyOptions[id].value.link_logo,
              parada: "",
              ruta: "",
              destino_final: "",
              referencia: "",
            }));
            setCpsData(companyOptions[id].value.cps_data);
            setRutas([]);
          });
        });
      },
    });
  };

  const selectParada = async () => {
    const uniqueParadas = [...new Set(cpsData.map((cps) => cps.cps_parada))];
    const paradaOptions = uniqueParadas.map((parada, index) => ({
      id: index,
      text: parada,
      value: parada,
    }));

    await MySwal.fire({
      title: "Seleccionar Parada",
      html: `
        <input type="text" id="searchParada" class="swal2-input" placeholder="Buscar Parada">
        ${paradaOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="parada-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchParada");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              parada: paradaOptions[id].value,
              ruta: "",
              destino_final: "",
              referencia: "",
            }));
            const filteredCps = cpsData.filter(
              (cps) => cps.cps_parada === paradaOptions[id].value
            );
            const uniqueRutas = [
              ...new Set(filteredCps.map((cps) => cps.cps_route)),
            ];
            setRutas(uniqueRutas);
          });
        });
      },
    });
  };

  const selectRuta = async () => {
    const rutaOptions = rutas.map((ruta, index) => ({
      id: index,
      text: ruta,
      value: ruta,
    }));

    await MySwal.fire({
      title: "Seleccionar Ruta",
      html: `
        <input type="text" id="searchRuta" class="swal2-input" placeholder="Buscar Ruta">
        ${rutaOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="ruta-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchRuta");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            const ruta = rutaOptions[id].value;
            setFormData((prevData) => ({
              ...prevData,
              ruta: ruta,
            }));
            const selectedCps = cpsData.find((cps) => cps.cps_route === ruta);
            setFormData((prevData) => ({
              ...prevData,
              destino_final: selectedCps.cps_final_destination,
              referencia: selectedCps.cps_shift_time,
            }));
          });
        });
      },
    });
  };

  const showRouteDetails = async (id) => {
    try {
      const record = await axios.get(
        `${process.env.BACKEND_URL}/api/getActivacionPista/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      const data = record.data;
      MySwal.fire({
        title: `Detalles de la Ruta`,
        html: `
          <p><strong>Empresa:</strong> ${data.empresa}</p>
          <p><strong>RIF:</strong> ${data.rif}</p>
          <p><strong>Parada:</strong> ${data.parada}</p>
          <p><strong>Ruta:</strong> ${data.ruta}</p>
          <p><strong>Destino Final:</strong> ${data.destino_final}</p>
          <p><strong>Hora de Salida:</strong> ${data.hora_salida}</p>
          <p><strong>Anden:</strong> ${data.anden}</p>
          <p><strong>Estatus:</strong> ${data.estatus}</p>
        `,
        confirmButtonText: "Cerrar",
      });
    } catch (error) {
      console.error("Error al mostrar detalles de la ruta", error);
      MySwal.fire({
        title: "Error",
        text: "Error al mostrar detalles de la ruta. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const changeStatus = async (id, currentStatus) => {
    const statusOptions = [
      "ACTIVO",
      "ANULADO",
      "EN PISTA",
      "CARGANDO",
      "SALIENDO",
      "SALIO",
    ];
    const { value: newStatus } = await MySwal.fire({
      title: "Cambiar Estatus",
      input: "select",
      inputOptions: statusOptions.reduce((options, status) => {
        options[status] = status;
        return options;
      }, {}),
      inputPlaceholder: "Selecciona un estatus",
      showCancelButton: true,
      confirmButtonText: "Cambiar",
      cancelButtonText: "Cancelar",
    });

    if (newStatus) {
      try {
        await axios.put(
          `${process.env.BACKEND_URL}/api/updateStatusActivacionPista/${id}`,
          { estatus: newStatus },
          {
            headers: {
              Authorization: `Bearer ${store.token}`,
            },
          }
        );
        MySwal.fire({
          title: "Estatus Actualizado",
          text: "El estatus ha sido actualizado exitosamente.",
          icon: "success",
          confirmButtonText: "Cerrar",
        });
        fetchTodayRecords(); // Refresh the records after status change
      } catch (error) {
        console.error("Error al actualizar el estatus", error);
        MySwal.fire({
          title: "Error",
          text: "Error al actualizar el estatus. Por favor, inténtelo de nuevo más tarde.",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs "
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd223"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Control de Pista
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form
          className="d-flex sd98f4e8wf4cwe987cwe984cwe"
          onSubmit={handleSubmit}
        >
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="fecha_viaje"
                value={formData.fecha_viaje}
                onChange={handleChange}
                required
              />
              <label>Fecha del viaje</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={formData.empresa}
                readOnly
                required
                onClick={selectCompany}
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="rif"
                value={formData.rif}
                readOnly
                required
              />
              <label>RIF</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="parada"
                value={formData.parada}
                readOnly
                required
                onClick={selectParada}
              />
              <label>Parada</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="text"
                name="ruta"
                value={formData.ruta}
                readOnly
                required
                onClick={selectRuta}
              />
              <label>Ruta</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destino_final"
                value={formData.destino_final}
                readOnly
                required
              />
              <label>Destino Final</label>
            </div>

            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_unidad"
                value={formData.numero_unidad}
                onChange={handleChange}
                required
              />
              <label>Número de Unidad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                onChange={handleChange}
                required
                style={{ textTransform: "uppercase" }}
              />
              <label>Placa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="anden"
                value={formData.anden}
                onChange={handleChange}
                required
              />
              <label>Andén de Salida</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="referencia"
                value={formData.referencia}
                readOnly
                required
              />
              <label>Hora CPS de Referencia</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="time"
                name="hora_salida"
                value={formData.hora_salida}
                onChange={handleChange}
                required
              />
              <label>Hora de Salida</label>
            </div>
          </div>

          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98f4v895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98f4v895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={fetchTodayRecords}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Registro de hoy
        </button>
      </div>
    </div>
  );
};
