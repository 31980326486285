import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../styles/login.css";
import { Context } from "./../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Helmet } from "react-helmet-async";

const MySwal = withReactContent(Swal);

export const Login = () => {
  const { actions, store } = useContext(Context);
  const navigate = useNavigate();
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 7000); // Cambiar después de 7 segundos
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/login",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const userData = response.data;
        actions.savetoken(response.data.token, userData);

        navigate("/Inicio");
        mostrarAlerta1();
      } else {
        console.error("Error al iniciar sesión", response.data);
      }
    } catch (error) {
      mostrarAlerta2();
      console.error("Error al iniciar sesión", error);
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Inicio de Sesión Exitoso</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Iniciar Sesión</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  return (
    <div>
      <Helmet>
        <title>Iniciar Sesión - Sasha Tech</title>
        <meta
          name="description"
          content="Inicia sesión en Sasha Tech para acceder a nuestros servicios de marketing digital y desarrollo web. Tu éxito comienza aquí."
        />
        <link rel="canonical" href="https://www.sashatech.com/Login" />
        <meta property="og:title" content="Iniciar Sesión - Sasha Tech" />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/sitemap.xml"
        />

        <meta
          property="og:description"
          content="Accede a tu cuenta en Sasha Tech y descubre nuestras soluciones de marketing digital y desarrollo web."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dirpdlfbu/image/upload/v1728129163/SashaTech/itlpjnnksxpp6tyvj4bb.png"
        />
        <link rel="icon" href="ellipse.png" type="image/x-icon" />
        <meta property="og:url" content="https://www.sashatech.com/login" />
        <meta property="og:type" content="website" />
        <meta name="theme-color" content="#041A22" />
      </Helmet>
      <div className="aq4-asd56sa54s">
        <div className="sa65d489as7f98re7f98q489re7w9d8q4wd9q8w7d98rwe74we w-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="sd45erge98h88r48ter9q4w9q87wd9q8wdqw98"
          >
            <path
              fill="#ffffff"
              fillOpacity="1"
              d="M0,160L80,154.7C160,149,320,139,480,138.7C640,139,800,149,960,165.3C1120,181,1280,203,1360,213.3L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div className="asd564er987ty98r79t8ut7yt9rv8wc4q9d8e4r98we4">
          <div className="container containerDeLoginjssegundo123">
            <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
            <p style={{ color: "#FF5B1B" }}>Inicia Sesión en Sasha Tech</p>
            <form onSubmit={handleSubmit} className="formularioDeLogin">
              <div className="input-group-login">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="email"
                  required
                />
                <label htmlFor="email">Correo electrónico</label>
              </div>
              <div className="input-group-login">
                <input
                  className="inputDeLogin"
                  type="password"
                  name="password"
                  required
                />
                <label htmlFor="text">Contraseña</label>
              </div>
              <button className="buttonCargadeDatosDeLogin" type="submit">
                Iniciar sesión <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
        </div>
        <div className="d65f4fg98ry7j98er7ge6t9r8g7w8e94ew">
          <div className="sd65f4r9y8u7utytr98red98ut7ytb98vqw897erwe"></div>
        </div>
      </div>
    </div>
  );
};
