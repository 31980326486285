import React, { useState, useContext } from "react";
import axios from "axios";
import "../../../../styles/AddDriverForm.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#cccccc",
    borderBottomStyle: "solid",
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  body: {
    fontSize: 12,
  },
  userDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
});

const AuditDocument = ({ audits, userDetails }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>Movimientos de Auditoría</Text>
      <View style={styles.userDetails}>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Nombre:</Text> {userDetails.name}
        </Text>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Email:</Text> {userDetails.email}
        </Text>
        <Text>
          <Text style={{ fontWeight: "bold" }}>Cédula:</Text>{" "}
          {userDetails.cedula}
        </Text>
      </View>
      {audits.map((audit, index) => (
        <View key={index} style={styles.section}>
          <Text style={styles.body}>
            <Text style={{ fontWeight: "bold" }}>Fecha y Hora:</Text>{" "}
            {audit.date_and_hour_register}
          </Text>
          <Text style={styles.body}>
            <Text style={{ fontWeight: "bold" }}>Movimiento:</Text>{" "}
            {audit.movement}
          </Text>
        </View>
      ))}
    </Page>
  </Document>
);

const AuditModalContent = ({ audits, userDetails }) => (
  <div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
        marginTop: "10px",
      }}
    >
      <h6 style={{ marginRight: "10px" }}>Descargar:</h6>
      <PDFDownloadLink
        document={<AuditDocument audits={audits} userDetails={userDetails} />}
        fileName="auditoria.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Cargando documento..."
          ) : (
            <i
              className="fa-solid fa-file-pdf"
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          )
        }
      </PDFDownloadLink>
    </div>
    <div id="audit-list">
      {audits.map((item, index) => (
        <div
          key={index}
          style={{ margin: 10, padding: 10, border: "1px solid #ccc" }}
        >
          <p>
            <strong>Fecha y Hora:</strong> {item.date_and_hour_register}
          </p>
          <p>
            <strong>Movimiento:</strong> {item.movement}
          </p>
        </div>
      ))}
    </div>
  </div>
);

export const Categoria = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    type_category: "",
    name: "",
    nota_opcional: "",
  });
  const [audits, setAudits] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mostrar ventana de carga mientras se sube el dato
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Subiendo categoría...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      // Enviar los datos del formulario
      await axios.post(
        process.env.BACKEND_URL + "/api/RegisterCategory",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );

      // Limpiar el formulario tras el éxito
      setFormData({
        type_category: "",
        name: "",
        nota_opcional: "",
      });

      // Cerrar la ventana de carga
      MySwal.close();

      // Mostrar mensaje de éxito
      mostrarAlerta1();
    } catch (error) {
      // Cerrar la ventana de carga
      MySwal.close();

      // Mostrar mensaje de error
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la categoría";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchUsers = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getUsers",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;
      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Usuarios Registrados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (user) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
            <p><strong>Nombre:</strong> ${user.first_name} ${user.last_name}</p>
            <p><strong>Email:</strong> ${user.email}</p>
            <button class="btn btn-secondary" style="background: #a9a9a9; border: none" id="viewUserAudit-${user.id}">Ver Movimientos</button>
          </div>
        `
          )
          .join("");

        MySwal.fire({
          title: "Usuarios Registrados",
          html: `<div id="users-list">${content}</div>`,
          confirmButtonText: "Cerrar",
        });

        data.forEach((user) => {
          document
            .getElementById(`viewUserAudit-${user.id}`)
            .addEventListener("click", () => viewUserAudit(user.id, user));
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        text: "Error al obtener usuarios registrados. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const viewUserAudit = async (userId, user) => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando movimientos de auditoría...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + `/api/getUserAudit/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      // Ensure user details are set correctly
      const userDetails = {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        cedula: user.user_cedula,
      };

      setUserDetails(userDetails);

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Movimientos de Auditoría",
          html: `<p style="text-align:center; margin:20px;">No se encontraron movimientos de auditoría.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        MySwal.fire({
          title: "Auditoría",
          html: `<div id="audit-modal-content"></div>`,
          confirmButtonText: "Cerrar",
        });

        const AuditModalContentWithProps = () => (
          <AuditModalContent audits={data} userDetails={userDetails} />
        );

        MySwal.update({
          html: <AuditModalContentWithProps />,
        });
      }
    } catch (error) {
      console.error("Error al obtener movimientos de auditoría", error);
      MySwal.fire({
        title: "Error",
        text: "Error al obtener movimientos de auditoría. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Categoría Registrada con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar la categoría</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const fetchCategorias = async () => {
    // Ventana mientras se cargan los datos
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Categorías...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/GetCategories",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.categories;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Categorías",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Categorías.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th style="text-align: start">Tipo de Categoría</th>
            <th style="text-align: start">Nombre</th>
            <th style="text-align: start">Nota</th>
            <th style="text-align: start">Acciones</th> <!-- Nueva columna para acciones -->
          </tr>
        `;

        const tableRows = data
          .map(
            (item) => `
              <tr>
                <td>${item.type_category}</td>
                <td>${item.name}</td>
                <td>${item.nota_opcional}</td>
                <td>
                  <button class="delete-btn" data-id="${item.id}" style="color: white; background-color: red; border: none; padding: 5px; cursor: pointer;">Eliminar</button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Categorías",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.type_category.toLowerCase().includes(searchTerm) ||
                    item.name.toLowerCase().includes(searchTerm) ||
                    item.nota_opcional.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr>
                      <td>${item.type_category}</td>
                      <td>${item.name}</td>
                      <td>${item.nota_opcional}</td>
                      <td>
                        <button class="delete-btn" data-id="${item.id}" style="color: white; background-color: red; border: none; padding: 5px; cursor: pointer;">Eliminar</button>
                      </td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Agregar funcionalidad para los botones de eliminar
            document.querySelectorAll(".delete-btn").forEach((button) => {
              button.addEventListener("click", async (event) => {
                const categoryId = event.currentTarget.getAttribute("data-id");

                // Preguntar al usuario si está seguro de eliminar
                const confirmation = await MySwal.fire({
                  title: "¿Estás seguro?",
                  text: "¡No podrás deshacer esta acción!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#d33",
                  cancelButtonColor: "#3085d6",
                  confirmButtonText: "Sí, eliminar",
                  cancelButtonText: "Cancelar",
                });

                if (confirmation.isConfirmed) {
                  // Ventana mientras se elimina el dato
                  MySwal.fire({
                    title: "Eliminando...",
                    html: `<p style="text-align:center; margin:20px;">Eliminando categoría...</p>`,
                    allowOutsideClick: false,
                    didOpen: () => {
                      MySwal.showLoading();
                    },
                  });

                  try {
                    // Eliminar la categoría
                    await axios.delete(
                      `${process.env.BACKEND_URL}/api/DeleteCategory/${categoryId}`,
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    MySwal.fire({
                      title: "Eliminada",
                      text: "La categoría ha sido eliminada exitosamente.",
                      icon: "success",
                      confirmButtonText: "Cerrar",
                    });

                    // Refrescar las categorías después de la eliminación
                    fetchCategorias();
                  } catch (error) {
                    MySwal.fire({
                      title: "Error",
                      text: "Hubo un error al eliminar la categoría.",
                      icon: "error",
                      confirmButtonText: "Cerrar",
                    });
                    console.error("Error al eliminar la categoría:", error);
                  }
                }
              });
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener Categorías", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener categorías. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      {/* <div className="container-fluid contaosijdasd-qw89d77asd2registromovimiento"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-11vh" }}
      >
        Registra Tus Categorías
      </h2> */}
      <div className="container asklnduiashndaijsnxansxsaas">
        <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
        <p style={{ color: "#0a306a" }}>
          Registra tus Categorías en sashatechve.com
        </p>
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="type_category"
                  onChange={handleChange}
                  value={formData.type_category}
                  required
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="INGRESO">INGRESO</option>
                  <option value="EGRESO">EGRESO</option>
                  <option value="METODO_DE_PAGO">METODO DE PAGO</option>
                  <option value="EMPRESA">EMPRESA</option>
                </select>
                <label>Tipo de Categoría</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="name"
                  required
                  style={{ textTransform: "uppercase" }}
                  onChange={handleChange}
                  value={formData.name}
                />
                <label>Nombre</label>
              </div>
            </div>
          </div>
          <div className="input-group-login2">
            <textarea
              className="inputDeLogin"
              type="number"
              name="nota_opcional"
              onChange={handleChange}
              value={formData.nota_opcional}
              style={{ minHeight: "6vh" }}
            />
            <label>Nota (opcional)</label>
          </div>

          <button
            className="buttonCargadeDatosDeLoginregistromovimiento"
            type="submit"
          >
            Registrar <i className="fa-solid fa-arrow-right"></i>
          </button>
          <div className="d-flex" style={{ marginTop: "12px" }}>
            <p>¿Alguna duda?,</p>
            <Link
              style={{
                marginLeft: "5px",
                textDecoration: "none",
                color: "#0a306a",
              }}
              to="/Ayudaysoporte"
            >
              <strong>Contáctanos</strong>
            </Link>
          </div>
        </form>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchCategorias}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Categorías
          </button>
        </div>
      </div>
    </div>
  );
};
