import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import "../../../../styles/Registro.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#cccccc",
    borderBottomStyle: "solid",
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  body: {
    fontSize: 12,
  },
  userDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
});

export const Registromovimiento = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [metodoPago, setMetodoPago] = useState([]); // Métodos de pago
  const [empresas, setEmpresas] = useState([]); // empresas
  const [filteredCategories, setFilteredCategories] = useState([]); // Categorías filtradas
  const [formData, setFormData] = useState({
    date: "",
    categoria_ingreso_egreso: "",
    categoria: "",
    monto: "",
    metodo_pago: "",
    empresa: "",
    ref: "",
    nota_opcional: "",
  });
  const [categories, setCategories] = useState([]);
  const [audits, setAudits] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [fecha, setFecha] = useState("");

  useEffect(() => {
    // Obtener la fecha actual
    const hoy = new Date();
    const fechaActual = hoy.toISOString().split("T")[0];
    setFecha(fechaActual);

    // Actualiza el campo de fecha en formData
    setFormData((prevData) => ({
      ...prevData,
      date: fechaActual,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/RegisterMovement",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        date: fecha,
        categoria_ingreso_egreso: "",
        categoria: "",
        monto: "",
        metodo_pago: "",
        empresa: "",
        ref: "",
        nota_opcional: "",
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchCategorias();
  }, []);

  const fetchCategorias = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Categorías...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/GetCategories2",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.categories;

      // Setear en los estados
      setCategories(data.INGRESO_EGRESO);
      setMetodoPago(data.METODO_PAGO);
      setEmpresas(data.EMPRESA);

      MySwal.close();
    } catch (error) {
      console.error("Error al obtener Categorías", error);
      MySwal.close();
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener categorías. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleCategoriaIngresoEgresoChange = (e) => {
    const { value } = e.target;

    // Filtrar categorías según el valor seleccionado
    const filtered = categories.filter((cat) => cat.type_category === value);

    setFilteredCategories(filtered); // Guardamos las categorías filtradas
    setFormData((prevData) => ({
      ...prevData,
      categoria_ingreso_egreso: value,
      categoria: "",
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Movimiento Registrado con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar el Movimiento</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const fetchMovements = async () => {
    // Ventana mientras se cargan los datos
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Movimientos...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/GetMovements",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.Movements;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Movimientos",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Movimientos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th style="text-align: start">Fecha</th>
            <th style="text-align: start">Entrada o Salida</th>
            <th style="text-align: start">Categoría</th>
            <th style="text-align: start">Monto</th>
            <th style="text-align: start">Método de Pago</th>
            <th style="text-align: start">Empresa</th>
            <th style="text-align: start">Ref</th>
            <th style="text-align: start">Nota</th>
            <th style="text-align: start">Acción</th>
          </tr>
        `;

        const tableRows = data
          .map(
            (item) => `
              <tr>
                <td>${item.date}</td>
                <td>${item.categoria_ingreso_egreso}</td>
                <td>${item.categoria}</td>
                <td>${item.monto}</td>
                <td>${item.metodo_pago}</td>
                <td>${item.empresa}</td>
                <td>${item.ref}</td>
                <td>${item.nota_opcional}</td>
                <td>
                  <button class="delete-btn" data-id="${item.id}" style="color: white; background-color: red; border: none; padding: 5px; cursor: pointer;">Eliminar</button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto; width: 900px">
            <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Movimientos",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    String(item.categoria_ingreso_egreso)
                      .toLowerCase()
                      .includes(searchTerm) ||
                    String(item.categoria).toLowerCase().includes(searchTerm) ||
                    String(item.monto).toLowerCase().includes(searchTerm) ||
                    String(item.metodo_pago)
                      .toLowerCase()
                      .includes(searchTerm) ||
                    String(item.empresa).toLowerCase().includes(searchTerm) ||
                    String(item.ref).toLowerCase().includes(searchTerm) ||
                    String(item.nota_opcional)
                      .toLowerCase()
                      .includes(searchTerm) ||
                    String(item.date).toLowerCase().includes(searchTerm) // Agregamos la búsqueda por fecha
                )
                .map(
                  (item) => `
                    <tr>
                      <td>${item.date}</td>
                      <td>${item.categoria_ingreso_egreso}</td>
                      <td>${item.categoria}</td>
                      <td>${item.monto}</td>
                      <td>${item.metodo_pago}</td>
                      <td>${item.empresa}</td>
                      <td>${item.ref}</td>
                      <td>${item.nota_opcional}</td>
                      <td>
                        <button class="delete-btn" data-id="${item.id}" style="color: white; background-color: red; border: none; padding: 5px; cursor: pointer;">Eliminar</button>
                      </td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Agregar funcionalidad para los botones de eliminar
            document.querySelectorAll(".delete-btn").forEach((button) => {
              button.addEventListener("click", async (event) => {
                const movementsId = event.currentTarget.getAttribute("data-id");

                // Preguntar al usuario si está seguro de eliminar
                const confirmation = await MySwal.fire({
                  title: "¿Estás seguro?",
                  text: "¡No podrás deshacer esta acción!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#d33",
                  cancelButtonColor: "#3085d6",
                  confirmButtonText: "Sí, eliminar",
                  cancelButtonText: "Cancelar",
                });

                if (confirmation.isConfirmed) {
                  // Ventana mientras se elimina el dato
                  MySwal.fire({
                    title: "Eliminando...",
                    html: `<p style="text-align:center; margin:20px;">Eliminando categoría...</p>`,
                    allowOutsideClick: false,
                    didOpen: () => {
                      MySwal.showLoading();
                    },
                  });

                  try {
                    // Eliminar la categoría
                    await axios.delete(
                      `${process.env.BACKEND_URL}/api/Deletemovements/${movementsId}`,
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    MySwal.fire({
                      title: "Eliminada",
                      text: "El movimiento ha sido eliminado exitosamente.",
                      icon: "success",
                      confirmButtonText: "Cerrar",
                    });

                    // Refrescar las categorías después de la eliminación
                    fetchMovements();
                  } catch (error) {
                    MySwal.fire({
                      title: "Error",
                      text: "Hubo un error al eliminar el movimiento.",
                      icon: "error",
                      confirmButtonText: "Cerrar",
                    });
                    console.error("Error al eliminar el movimiento:", error);
                  }
                }
              });
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener Movimiento", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener Movimiento. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      {/* <div className="container-fluid contaosijdasd-qw89d77asd2registromovimiento"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-11vh" }}
      >
        Registra Tus Ingresos y Egresos
      </h2> */}
      <div className="container asklnduiashndaijsnxansxsaas">
        <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
        <p style={{ color: "#0a306a" }}>
          Registra tus Movimientos en sashatechve.com
        </p>

        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="date"
                required
                onChange={handleChange}
                value={formData.date}
              />
              <label>Fecha</label>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="categoria_ingreso_egreso"
                  onChange={handleCategoriaIngresoEgresoChange}
                  value={formData.categoria_ingreso_egreso}
                  required
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="INGRESO">INGRESO</option>
                  <option value="EGRESO">EGRESO</option>
                </select>
                <label>Tipo de Categoría</label>
              </div>
            </div>

            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="categoria"
                onChange={handleChange}
                value={formData.categoria}
                required
              >
                <option value="" disabled>
                  Selecciona
                </option>
                {filteredCategories.map((categoria) => (
                  <option key={categoria.id} value={categoria.name}>
                    {categoria.name}
                  </option>
                ))}
              </select>
              <label>Categoría</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="number"
                name="monto"
                onChange={handleChange}
                value={formData.monto}
                required
              />
              <label>Monto</label>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="metodo_pago"
                  onChange={handleChange}
                  value={formData.metodo_pago}
                  required
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  {metodoPago.map((metodo) => (
                    <option key={metodo.id} value={metodo.name}>
                      {metodo.name}
                    </option>
                  ))}
                </select>
                <label>Método de Pago</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="empresa"
                  onChange={handleChange}
                  value={formData.empresa}
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  {empresas.map((empresa) => (
                    <option key={empresa.id} value={empresa.name}>
                      {empresa.name}
                    </option>
                  ))}
                </select>
                <label>Empresa (opcional)</label>
              </div>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="number"
                name="ref"
                onChange={handleChange}
                value={formData.ref}
              />
              <label>Número de Referencia (opcional)</label>
            </div>
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="number"
                name="nota_opcional"
                onChange={handleChange}
                value={formData.nota_opcional}
                style={{ minHeight: "6vh" }}
              />
              <label>Nota (opcional)</label>
            </div>
          </div>
          <button
            className="buttonCargadeDatosDeLoginregistromovimiento"
            type="submit"
          >
            Registrar <i className="fa-solid fa-arrow-right"></i>
          </button>
          <div className="d-flex" style={{ marginTop: "12px" }}>
            <p>¿Alguna duda?,</p>
            <Link
              style={{
                marginLeft: "5px",
                textDecoration: "none",
                color: "#0a306a",
              }}
              to="#"
            >
              <strong>Contáctanos</strong>
            </Link>
          </div>
        </form>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchMovements}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Movimientos
          </button>
        </div>
      </div>
    </div>
  );
};
