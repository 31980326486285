import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const PreciosDestinosFinalRL = () => {
  const [formData, setFormData] = useState({
    destiny_final: "",
    price_dolar: "",
    type_service: "",
  });
  const [precios, setPrecios] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { store, actions } = useContext(Context);

  const fetchDestinos = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/getDestinos`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.destinos;
      MySwal.close();
      selectDestinoDialog(data);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener destinos. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchPrecios = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/getPreciosDestinos`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.precios;
      MySwal.close();
      showPreciosDialog(data);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener precios. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const selectDestino = () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando destinos...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
        fetchDestinos();
      },
    });
  };

  const showPrecios = () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando precios...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
        fetchPrecios();
      },
    });
  };

  const selectDestinoDialog = (destinos) => {
    const destinoOptions = destinos.map((destino, index) => {
      const incompleteServices = Object.keys(destino.services).filter(
        (service) => !destino.services[service]
      );
      const backgroundColor = destino.completed ? "#0080001c" : "#ff00001a";
      return {
        id: index,
        text: `<strong>Destino:</strong> ${destino.destiny_final}<br/><strong>Servicios Faltantes:</strong> ${incompleteServices.join(", ") || "Ninguno"}`,
        value: destino,
        backgroundColor,
      };
    });

    MySwal.fire({
      title: "Seleccionar Destino",
      html: `
        <input type="text" id="searchDestino" class="swal2-input" placeholder="Buscar Destino">
        ${destinoOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc; background-color: ${option.backgroundColor};" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="destino-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchDestino");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              destiny_final: destinoOptions[id].value.destiny_final,
            }));
          });
        });
      },
    });
  };

  const showPreciosDialog = (precios) => {
    const precioOptions = precios.map((precio, index) => ({
      id: precio.id,
      text: `<strong>Destino:</strong> ${precio.destiny_final}<br/><strong>Servicio:</strong> ${precio.type_service}<br/><strong>Precio:</strong> ${precio.price_dolar}`,
      value: precio,
    }));

    MySwal.fire({
      title: "Precios Registrados",
      html: `
        <input type="text" id="searchPrecio" class="swal2-input" placeholder="Buscar Precio">
        ${precioOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="precio-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchPrecio");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            const selectedPrecio = precioOptions.find(
              (option) => option.id == id
            ).value;
            MySwal.clickConfirm();
            editPrecioDialog(selectedPrecio);
          });
        });
      },
    });
  };

  const editPrecioDialog = (precio) => {
    setFormData({
      destiny_final: precio.destiny_final,
      price_dolar: precio.price_dolar,
      type_service: precio.type_service,
    });

    MySwal.fire({
      title: "Editar Precio",
      html: `
        <div>
          <label>Destino Final</label>
          <input type="text" id="destiny_final" class="swal2-input" value="${precio.destiny_final}" readonly>
        </div>
        <div>
          <label>Tipo de Servicio</label>
          <input type="text" id="type_service" class="swal2-input" value="${precio.type_service}" readonly>
        </div>
        <div>
          <label>Precio</label>
          <input type="text" id="price_dolar" class="swal2-input" value="${precio.price_dolar}">
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
      preConfirm: () => {
        const newPrice = document.getElementById("price_dolar").value;
        return { id: precio.id, newPrice };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { id, newPrice } = result.value;
        editPrecio(id, newPrice);
      }
      setFormData({
        destiny_final: "",
        price_dolar: "",
        type_service: "",
      });
    });
  };

  const editPrecio = async (id, price_dolar) => {
    try {
      const response = await axios.put(
        `${process.env.BACKEND_URL}/api/editPrecioDestino`,
        { id, price_dolar },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      MySwal.fire("Éxito", response.data.message, "success");
    } catch (error) {
      MySwal.fire("Error", error.response.data.message, "error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    MySwal.fire({
      title: "Registrando...",
      html: `<p style="text-align:center; margin:20px;">Empujando el destino con su precio...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.post(
        `${process.env.BACKEND_URL}/api/registerPrecioDestino`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setFormData({
        destiny_final: "",
        price_dolar: "",
        type_service: "",
      });
      setIsSubmitting(false);
      MySwal.close();
      MySwal.fire("Éxito", response.data.message, "success");
    } catch (error) {
      setIsSubmitting(false);
      MySwal.close();
      MySwal.fire("Error", error.response.data.message, "error");
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Precios Destino Final Rutas Largas
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form className="formularioDeLogin w-100" onSubmit={handleSubmit}>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destiny_final"
                required
                value={formData.destiny_final}
                readOnly
                onClick={selectDestino}
              />
              <label>Destino Final</label>
            </div>
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="type_service"
                required
                value={formData.type_service}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Selecciona
                </option>
                <option value="BUSCAMA">BUSCAMA</option>
                <option value="EJECUTIVO">EJECUTIVO</option>
                <option value="YUTONG">YUTONG</option>
              </select>
              <label>Tipo de Servicio</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="number"
                name="price_dolar"
                required
                value={formData.price_dolar}
                onChange={handleInputChange}
              />
              <label>Precio Destino Final</label>
            </div>
          </div>

          <button
            className="buttonCargadeDatosDeLogin"
            type="submit"
            disabled={isSubmitting}
          >
            Registrar <i className="fa-solid fa-arrow-right"></i>
          </button>
        </form>
      </div>
      <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
        <button
          className="btn asd98q7wd65q1wd8xqw49x8e4wc"
          onClick={showPrecios}
        >
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Ver Precios Registrados
        </button>
      </div>
    </div>
  );
};
