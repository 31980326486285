import React from "react";
import "../../styles/nosotros.css";
import "animate.css";
import { Helmet } from "react-helmet-async";

export const SobreNosotros = () => {
  return (
    <div
      className="conainer as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd"
      style={{ minHeight: "100vh", marginBottom: "70px" }}
    >
      <Helmet>
        <title>Sobre Nosotros - Sasha Tech</title>
        <meta
          name="description"
          content="Conoce más sobre Sasha Tech, una empresa de marketing digital y desarrollo web, enfocada en ofrecer soluciones innovadoras para negocios en Venezuela y España."
        />
        <link rel="canonical" href="https://www.sashatech.com/sobre-nosotros" />
        <meta
          property="og:title"
          content="Sasha Tech - Diseñador Web en Caracas"
        />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/sitemap.xml"
        />

        <meta
          property="og:description"
          content="Contáctanos para servicios de marketing digital, SEO, y desarrollo web."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dirpdlfbu/image/upload/v1728129163/SashaTech/itlpjnnksxpp6tyvj4bb.png"
        />
        <link rel="icon" href="ellipse.png" type="image/x-icon" />
        <meta
          property="og:url"
          content="https://sashatechve.com/SobreNosotros"
        />
        <meta property="og:type" content="website" />
        <meta name="theme-color" content="#041A22" />
      </Helmet>
      <div className="as54s98df7er98g7erw984cwe">
        <h1 style={{ color: "white", marginTop: "40px" }}>Sobre Sasha Tech</h1>
      </div>
      <div
        className="container as65d489f7889we7f89ewwe"
        style={{ paddingTop: "70px" }}
      >
        <div className="s56d4a9s8d7we987vw98v7e894ewe2356">
          <h1 style={{ color: "black", marginBottom: "20px" }}>
            Sobre Nosotros
          </h1>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            Sasha Tech, la empresa que busca transformar la manera en que
            manejas tu presencia en línea, ofrece un seguimiento constante de
            tus plataformas digitales. Desde tu cuenta de Sasha Tech, podrás
            optimizar procesos y disfrutar de una visión integral de tu
            estrategia digital.
          </p>
          <h3
            className="asd65sd4frer78text"
            style={{ color: "black", marginBottom: "20px" }}
          >
            Nuestros Logros
          </h3>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            <ul>
              <li>Proyectos digitales exitosos en múltiples sectores</li>
              <li>
                Mejoras significativas en el posicionamiento SEO de nuestros
                clientes
              </li>
            </ul>
          </p>
        </div>
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe2356"
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="sdf5d4erge4rg98e7rg98er49t87ge65r4fwe897rwe98r4wer98we7"></div>
        </div>
      </div>
      <div className="dfsd5f4t7897webackasf">
        <h1 style={{ textAlign: "center" }}>
          S a s h a ´ s <br />T e c h{" "}
        </h1>
      </div>
      <div className="container as65d489f7889we7f89ewwe">
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe2356"
          style={{ padding: "20px" }}
        >
          <div className="df654g89er7tg98er7fw89e4e987wfe98fw4e98we7"></div>
        </div>
        <div
          className="s56d4a9s8d7we987vw98v7e894ewe2356"
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h1 style={{ color: "black", marginBottom: "20px" }}>
            Impulsa tu negocio con nosotros
          </h1>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            Descubre nuestras soluciones digitales y maximiza tu potencial.
          </p>
          <h3
            className="asd65sd4frer78text"
            style={{ color: "black", marginBottom: "20px" }}
          >
            Por qué Elegirnos
          </h3>
          <p style={{ color: "black", fontSize: "20px", marginBottom: "20px" }}>
            <ul>
              <li>Soluciones personalizadas para cada cliente</li>
              <li>Enfoque en resultados tangibles y medibles</li>
              <li>Atención al cliente excepcional y soporte continuo</li>
            </ul>
          </p>
        </div>
      </div>

      <div className="sdf564rt98h7r987trcsa">
        <div className="container a5s6d5f4g89y7th98wrf4w9e84trhwf48e">
          <div className="sd654dr98gfw7e897r89t7gerfwe">
            <h1 style={{ color: "white" }}>Que nos hace Diferentes</h1>
            <p
              style={{ color: "white" }}
              className="s5d64f987tythre8wfqe8gtyuras54dtext2"
            >
              Además de haber haber escogido, meticulosamente las mejores
              prácticas a nivel internacional por nuestra experiencia en el
              extranjero en países muy desarrollados, hemos ingeniado la forma
              de ofrecerle a las empresas un todo en uno
            </p>
          </div>
          <div className="sd654dr98gfw7e897r89t7gerfwe2">
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>Contamos con la Experiencia</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Mejoramos tus números en el mercado local
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>Totalmente Propio</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  El Sistema es totalmente del Cliente
                </p>
              </div>
            </div>
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>Crearás tus usuarios</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Crea usuarios asigna tareas, y personaliza tu Sistema
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>Unifica y potencia</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Automatiza, Controla, Potencia y Sobre todo Disfruta del
                  proceso
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
