import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const CierresRutasCortas = () => {
  const [cierres, setcierres] = useState([]);
  const { store } = useContext(Context);

  useEffect(() => {
    fetchcierretodos();
  }, []);

  const fetchcierretodos = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/gettodoscierresrc",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setcierres(response.data.cierres);
    } catch (error) {
      console.error("Error fetching Cierres", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un error al obtener los Cierres.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px", paddingBottom: "70px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd2"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Cierre Listines Rutas Cotas
      </h2>
      <div className="container movimientoss9a87d89qwdqw98d4qw">
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="aoshduishdihwe89f78w9e7we7we7fcw">
                <th>Registro</th>
                <th>Id</th>
                <th>Usuario</th>
                <th>Documento</th>
              </tr>
            </thead>
            <tbody>
              {cierres.map((item) => (
                <tr key={item.id}>
                  <td>{item.fecha_hora_registro}</td>
                  <td>{item.id}</td>
                  <td>{item.usuario}</td>
                  <td>
                    <a
                      href={item.link_document}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-primary">PDF</button>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
