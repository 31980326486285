// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asd65sd4frer78text {
  color: black !important;
}
.sdf5d4erge4rg98e7rg98er49t87ge65r4fwe897rwe98r4wer98we7 {
  background-image: url("https://res.cloudinary.com/dirpdlfbu/image/upload/v1726410574/Graham%20Project/cd78qidsygtfesxw1bqg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 60vh;
  @media (max-width: 768px) {
    min-height: 30vh;
  }
}
.df654g89er7tg98er7fw89e4e987wfe98fw4e98we7 {
  background-image: url("https://res.cloudinary.com/dirpdlfbu/image/upload/v1728145199/SashaTech/wfmiksqhhmhnjgo8afod.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 60vh;
  @media (max-width: 768px) {
    min-height: 30vh;
  }
}
.s56d4a9s8d7we987vw98v7e894ewe2356 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.dfsd5f4t7897webackasf {
  background-color: #241c15;
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/front/styles/nosotros.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;AACA;EACE,gIAAgI;EAChI,2BAA2B;EAC3B,wBAAwB;EACxB,4BAA4B;EAC5B,WAAW;EACX,gBAAgB;EAChB;IACE,gBAAgB;EAClB;AACF;AACA;EACE,yHAAyH;EACzH,2BAA2B;EAC3B,wBAAwB;EACxB,4BAA4B;EAC5B,WAAW;EACX,gBAAgB;EAChB;IACE,gBAAgB;EAClB;AACF;AACA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB;IACE,WAAW;EACb;AACF;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".asd65sd4frer78text {\n  color: black !important;\n}\n.sdf5d4erge4rg98e7rg98er49t87ge65r4fwe897rwe98r4wer98we7 {\n  background-image: url(\"https://res.cloudinary.com/dirpdlfbu/image/upload/v1726410574/Graham%20Project/cd78qidsygtfesxw1bqg.png\");\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n  width: 100%;\n  min-height: 60vh;\n  @media (max-width: 768px) {\n    min-height: 30vh;\n  }\n}\n.df654g89er7tg98er7fw89e4e987wfe98fw4e98we7 {\n  background-image: url(\"https://res.cloudinary.com/dirpdlfbu/image/upload/v1728145199/SashaTech/wfmiksqhhmhnjgo8afod.png\");\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n  width: 100%;\n  min-height: 60vh;\n  @media (max-width: 768px) {\n    min-height: 30vh;\n  }\n}\n.s56d4a9s8d7we987vw98v7e894ewe2356 {\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n.dfsd5f4t7897webackasf {\n  background-color: #241c15;\n  height: 20vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
