import React, { useEffect, useState } from "react";
import "../../styles/navbar.css";
import { Link, useLocation } from "react-router-dom";
import animationData from "../../../../nuevo.json";
import Lottie from "react-lottie";
import "animate.css";
import "../../styles/material-kit.css";
import "../../styles/material-kit.min.css";
import "../../styles/nucleo-icons.css";
import "../../styles/nucleo-svg.css";

export const Navbar = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [scrolling, setScrolling] = useState(false);
  const [togglerOpen, setTogglerOpen] = useState(false);
  const [animationInProgress, setAnimationInProgress] = useState(false);
  const [progress, setProgress] = useState(0.5);
  const [showPointer, setShowPointer] = useState(true);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    initialSegment: [0, progress * 130], // Assuming 46 frames total
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseMenu = () => {
    setTogglerOpen(false);
  };

  const handleTogglerClick = () => {
    setTogglerOpen(!togglerOpen);

    if (!animationInProgress) {
      setAnimationInProgress(true);

      // Inicia o detiene la animación según el estado del menú
      const animationFrame = togglerOpen ? 0 : 46;
      setTimeout(() => {
        setProgress(togglerOpen ? 0.5 : 1); // Set progress to 0.5 when closing, 1 when opening
        setAnimationInProgress(false);
      }, animationFrame);
    }
  };

  const logoImage =
    location.pathname === "/" ? (
      <img
        src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1722184710/1/SASHA%20TECH/uer8zc1spuqpoax4k4xj.png"
        className="img98765461"
        alt="Logo"
      />
    ) : (
      <img
        src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1722184710/1/SASHA%20TECH/uer8zc1spuqpoax4k4xj.png"
        className="img98765461"
        alt="Other Logo"
      />
    );

  const linkColor = location.pathname === "/" ? "white" : "white";

  const handleScroll = () => {
    if (window.scrollY > 0 && showPointer) {
      setShowPointer(false);
    } else if (window.scrollY === 0 && !showPointer) {
      setShowPointer(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showPointer]);

  const [background, setBackground] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setBackground("white");
      } else {
        setBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="po984ntynb51re9vwe9fwe-nav-d98c49w position-sticky z-index-sticky top-0">
      <div
        className="row as-dadqnf-gnmyumt-nfdbv84ds"
        style={{ position: "absolute", width: "100%" }}
      >
        <div className="col-12">
          <nav
            className={`navbar navbar-expand-lg top-0 z-index-fixed position-absolute w-100 ${
              scrolling || togglerOpen ? "navbar-white" : ""
            }`}
            style={{
              boxShadow: "none",
              background: background,
              backdropFilter: "blur(15px)",
              left: "0",
            }}
          >
            <div className="container-fluid px-0">
              <Link to="/">
                <span
                  className="navbar-brand mb-0 h1"
                  style={{ paddingLeft: "0.75rem" }}
                >
                  {logoImage}
                </span>
              </Link>
              <button
                className="navbar-toggler shadow-none ms-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navigation"
                aria-controls="navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ border: "1px solid black" }}
              >
                <span
                  className="navbar-toggler-icon mt-2"
                  style={{ marginLeft: "2px" }}
                >
                  <span className="navbar-toggler-bar bg-light bar1"></span>
                  <span className="navbar-toggler-bar bg-light bar2"></span>
                  <span className="navbar-toggler-bar bg-light bar3"></span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse pt-3 pb-2 py-lg-0 w-100"
                id="navigation"
              >
                <ul className="navbar-nav navbar-nav-hover ms-auto asd-asd8asd-as94dasdas984qwdqcwiocnd">
                  <li className="nav-item dropdown dropdown-hover mx-2">
                    <a
                      className="nav-link ps-2 d-flex cursor-pointer align-items-center text-dark"
                      id="dropdownMenuPages"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Sobre Nosotros
                      <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-animation ms-n3 dropdown-md p-3 border-radius-xl mt-0 mt-lg-3"
                      aria-labelledby="dropdownMenuPages"
                    >
                      <div className="d-none d-lg-block">
                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1">
                          Nosotros
                        </h6>
                        <Link
                          to="/blog"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/blog">Nuestro Blog</Link>
                        </Link>
                        <Link
                          to="/SobreNosotros"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/SobreNosotros">Sobre Nosotros</Link>
                        </Link>
                        <Link
                          Link
                          to="/contactanos"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/contactanos">Contáctanos</Link>
                        </Link>
                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1 mt-3">
                          Cuenta
                        </h6>
                        <Link
                          to="/registro"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/registro">Registrarse</Link>
                        </Link>
                        <Link
                          to="/login"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/login">Iniciar Sesión</Link>
                        </Link>
                      </div>

                      <div className="d-lg-none">
                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1">
                          NOSOTROS
                        </h6>

                        <Link
                          to="/blog"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/blog">Nuestro Blog</Link>
                        </Link>
                        <Link
                          to="/SobreNosotros"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/SobreNosotros">Sobre Nosotros</Link>
                        </Link>
                        <Link
                          to="/contactanos"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/contactanos">Contáctanos</Link>
                        </Link>

                        <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex align-items-center px-1 mt-3">
                          CUENTA
                        </h6>
                        <Link
                          to="/registro"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/registro">Registrarse</Link>
                        </Link>
                        <Link
                          to="/Login"
                          className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 border-radius-md"
                        >
                          <Link to="/Login">Iniciar Sesión</Link>
                        </Link>
                      </div>
                    </div>
                  </li>
                  <Link
                    to="/SobreNosotros"
                    className="nav-item dropdown dropdown-hover mx-2"
                  >
                    <a
                      className="nav-link ps-2 d-flex cursor-pointer align-items-center"
                      id="dropdownMenuBlocks"
                      // data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Sasha Tech
                      <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-3"
                      aria-labelledby="dropdownMenuBlocks"
                    ></ul>
                  </Link>
                  <li className="nav-item dropdown dropdown-hover mx-2">
                    <a
                      className="nav-link ps-2 d-flex cursor-pointer align-items-center text-dark"
                      id="dropdownMenuDocs"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Contacto y Soporte
                      <i className="fa-solid ssdajksdn-nav-item-fle fa-chevron-down"></i>
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end dropdown-menu-animation dropdown-md dropdown-md-responsive mt-0 mt-lg-3 p-3 border-radius-lg"
                      aria-labelledby="dropdownMenuDocs"
                    >
                      <div className="d-none d-lg-block">
                        <ul className="list-group">
                          <li className="nav-item list-group-item border-0 p-0">
                            <Link
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              to="/contactanos"
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                Contáctanos!
                              </h6>
                              <span className="text-sm">
                                Nuestro equipo está listo para ayudarte.
                                ¡Estamos a solo un clic!
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <Link
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              to="/blog/detalle/2"
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                10 cosas sobre el SEO
                              </h6>
                              <span className="text-sm">
                                No solo son Palabras claves...
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <Link
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              to="/blog/detalle/3"
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                5 Curiosidades Diseño WEB
                              </h6>
                              <span className="text-sm">
                                Aprende a diferenciar entre una web profesional
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <Link
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              to="/blog/detalle/4"
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                Conoce la nube
                              </h6>
                              <span className="text-sm">
                                ¿Por qué los datos a una nube es la mejor
                                opción?
                              </span>
                            </Link>
                          </li>
                          <li className="nav-item list-group-item border-0 p-0">
                            <Link
                              className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                              to="/blog/detalle/5"
                            >
                              <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                                10 Errores comunes en el SEO
                              </h6>
                              <span className="text-sm">
                                Que no te pase, rompe el ranking en busquedas
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="row d-lg-none">
                        <div className="col-md-12 g-0">
                          <Link
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            to="/contactanos"
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              Contáctanos!
                            </h6>
                            <span className="text-sm">
                              Nuestro equipo está listo para ayudarte.
                            </span>
                          </Link>

                          <Link
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            to="/blog/detalle/2"
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              10 cosas sobre el SEO
                            </h6>
                            <span className="text-sm">
                              No solo son Palabras claves...
                            </span>
                          </Link>

                          <Link
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            to="/blog/detalle/3"
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              5 Curiosidades Diseño WEB
                            </h6>
                            <span className="text-sm">
                              Como es una web profesional
                            </span>
                          </Link>

                          <Link
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            to="/blog/detalle/4"
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              Conoce la nube
                            </h6>
                            <span className="text-sm">
                              ¿Por qué guardar en nube es lo mejor?
                            </span>
                          </Link>

                          <Link
                            className="dropdown-item a-s4d9a8s4da99sqsdq-item-987965 py-2 ps-3 border-radius-md"
                            to="/blog/detalle/5"
                          >
                            <h6 className="dropdown-header a4smaoisqwda-sdqd4q9s4da9sqs font-weight-bolder d-flex justify-content-cente align-items-center p-0">
                              10 Errores comunes en el SEO
                            </h6>
                            <span className="text-sm">
                              Rompe el ranking en busquedas
                            </span>
                          </Link>
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li className="nav-item ms-lg-auto"></li>
                  <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
                    <Link
                      to="/Login"
                      className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0"
                      style={{ minWidth: "0" }}
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fa-solid fa-right-to-bracket as-da4s9da8s4d9qd5a6s"
                      ></i>
                      <span>Inicia Sesión</span>
                    </Link>
                  </li>
                  <li className="nav-item sd4fsd65f4we8f7we98f7we98f4wef87efw4e">
                    <Link
                      to="/Contactanos"
                      className="btn a-sd4q98dq-sdas98d4as-btn22 mb-0"
                      style={{ minWidth: "0" }}
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fa-solid fa-right-to-bracket as-da4s9da8s4d9qd5a6s"
                      ></i>
                      <span>Contáctanos</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
