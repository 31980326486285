import React, { useState, useContext } from "react";
import axios from "axios";
import "../../styles/UserHome.css";
import "../../styles/Registro.css";
import "../../styles/AddDriverForm.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#cccccc",
    borderBottomStyle: "solid",
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  body: {
    fontSize: 12,
  },
  userDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
});

export const NewRegister = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    account_type: "",
    terminal_ubication: "",
    company: "",
    company_rif: "",
    email: "",
    rol: "",
    user_cedula: "",
    user_phone: "",
  });
  const [audits, setAudits] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(process.env.BACKEND_URL + "/api/signup", formData, {
        headers: {
          Authorization: "Bearer " + store.token,
        },
      });
      setFormData({
        first_name: "",
        last_name: "",
        account_type: "",
        terminal_ubication: "",
        company: "",
        company_rif: "",
        email: "",
        rol: "",
        user_cedula: "",
        user_phone: "",
      });
      mostrarAlerta1();
      navigate("/Login");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Usuario Registrado con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar al usuario</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas">
        <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
        <p style={{ color: "#0a306a" }}>
          Registra tus Usuarios en sashatechve.com
        </p>
        <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="company"
                required
                onChange={handleChange}
                value={formData.company}
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="company_rif"
                required
                onChange={handleChange}
                value={formData.company_rif}
              />
              <label>RIF</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="terminal_ubication"
                required
                onChange={handleChange}
                value={formData.terminal_ubication}
              />
              <label>Ubicacion de Terminal</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="first_name"
                required
                onChange={handleChange}
                value={formData.first_name}
              />
              <label>Nombre</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="text"
                name="last_name"
                required
                onChange={handleChange}
                value={formData.last_name}
              />
              <label>Apellido</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="email"
                name="email"
                required
                onChange={handleChange}
                value={formData.email}
              />
              <label>Correo Electrónico</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="rol"
                onChange={handleChange}
                value={formData.rol}
              >
                <option value="" disabled>
                  Selecciona un rol
                </option>
                <option value="GENERAL">GENERAL</option>
              </select>
              <label>Rol Usuario</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="number"
                name="user_cedula"
                onChange={handleChange}
                value={formData.user_cedula}
                required
              />
              <label>Cédula Usuario</label>
            </div>
            <div className="input-group-login">
              <input
                className="inputDeLogin"
                type="number"
                name="user_phone"
                onChange={handleChange}
                value={formData.user_phone}
                required
              />
              <label>Teléfono Usuario</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="account_type"
                onChange={handleChange}
                value={formData.account_type}
              >
                <option value="" disabled>
                  Selecciona
                </option>
                <option value="USER">USUARIO SIMPLE</option>
                <option value="TERMINAL">ADMINISTRADOR DE TERMINAL</option>
                <option value="COMPANY_OF_TERMINAL">
                  EMPRESA DENTRO DE TERMINAL
                </option>
                <option value="ADMIN">ADMINISTRADOR</option>
              </select>
              <label>Tipo de Cuenta</label>
            </div>
          </div>
          <button className="buttonCargadeDatosDeLogin" type="submit">
            Registrarse <i className="fa-solid fa-arrow-right"></i>
          </button>
          <div className="d-flex" style={{ marginTop: "12px" }}>
            <p>¿Alguna duda?,</p>
            <Link
              style={{
                marginLeft: "5px",
                textDecoration: "none",
                color: "#0a306a",
              }}
              to="#"
            >
              <strong>Contáctanos</strong>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
