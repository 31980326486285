import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/BlogNoticias.css";
import { Helmet } from "react-helmet-async";

export const BlogNoticias = () => {
  const blogsData = [
    {
      id: 2,
      titulo: "10 Cosas que no sabías sobre el SEO",
      descripcion:
        "El SEO va más allá de las palabras clave. Descubre 10 curiosidades que no conocías sobre el posicionamiento en buscadores y cómo puede impactar en la visibilidad de tu negocio online.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728141844/SashaTech/zyi8angmqgkazkyw6d9a.jpg')",
    },
    {
      id: 3,
      titulo: "5 Curiosidades sobre el Diseño Web",
      descripcion:
        "El diseño web no solo trata de estética. Aprende cinco aspectos esenciales que marcan la diferencia entre una página web profesional y una que no lo es. ¡Haz que tu web destaque!",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728141977/SashaTech/locvsbvwzcpnqgt5sixz.jpg')",
    },
    {
      id: 4,
      titulo: "Por qué tu empresa necesita una presencia en la nube",
      descripcion:
        "Migrar a la nube ya no es una opción, es una necesidad. Te contamos cómo implementar soluciones en la nube puede optimizar el rendimiento y la eficiencia de tu negocio.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728142860/SashaTech/pwin8xarsx8indfjztes.png')",
    },
    {
      id: 5,
      titulo: "10 Errores comunes en el SEO que debes evitar",
      descripcion:
        "Muchos negocios cometen los mismos errores cuando intentan mejorar su SEO. Te explicamos los 10 errores más comunes y cómo evitarlos para que tu página logre un mejor ranking.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728143011/SashaTech/du9m8nd3j28dfgkvovvl.jpg')",
    },
    {
      id: 6,
      titulo: "El impacto de la velocidad de tu web en el SEO",
      descripcion:
        "¿Sabías que la velocidad de carga de tu página web es un factor clave para tu SEO? Aprende cómo mejorar la velocidad de tu sitio y aumentar tu clasificación en los buscadores.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728143078/SashaTech/q9jowlijw2maff0kmjn1.png')",
    },
    {
      id: 7,
      titulo: "5 Claves para optimizar tu página web para móviles",
      descripcion:
        "En un mundo cada vez más móvil, tu web debe estar lista para los usuarios de dispositivos móviles. Descubre las claves para optimizar tu sitio web y brindar una experiencia excelente en cualquier pantalla.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728143138/SashaTech/prvp3pde5mhajhryelht.png')",
    },
    {
      id: 1,
      titulo: "¿Quién es SASHA TECH y cómo puede ayudarte?",
      descripcion:
        "SASHA TECH es una empresa de marketing digital y soluciones tecnológicas con sede en España y Venezuela. Ofrecemos servicios de posicionamiento SEO, desarrollo web y sistemas en la nube. Descubre cómo podemos ayudarte a mejorar tu presencia en línea y optimizar tus procesos.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728129163/SashaTech/itlpjnnksxpp6tyvj4bb.png')",
    },
  ];
  return (
    <div
      className="as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd"
      style={{ minHeight: "100vh", marginBottom: "70px" }}
    >
      <Helmet>
        <title>Blog - Sasha Tech</title>
        <meta
          name="description"
          content="Explora nuestro blog para obtener las últimas noticias, consejos y artículos sobre marketing digital y desarrollo web. Aprende y crece con Sasha Tech."
        />
        <link rel="canonical" href="https://www.sashatech.com/blog" />
        <meta property="og:title" content="Blog - Sasha Tech" />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/sitemap.xml"
        />

        <meta
          property="og:description"
          content="Accede a tu cuenta en Sasha Tech y descubre nuestras soluciones de marketing digital y desarrollo web."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dirpdlfbu/image/upload/v1728129163/SashaTech/itlpjnnksxpp6tyvj4bb.png"
        />
        <link rel="icon" href="ellipse.png" type="image/x-icon" />
        <meta property="og:url" content="https://www.sashatech.com/blog" />
        <meta property="og:type" content="website" />
        <meta name="theme-color" content="#041A22" />
      </Helmet>
      <div className="as54s98df7er98g7erw984cwe">
        <h1 style={{ color: "white", marginTop: "40px" }}>Blog Sasha Tech</h1>
      </div>
      <div className="container d-flex blogs-container mt-5 mb-6">
        {/* Última noticia en una columna separada */}
        {blogsData.slice(-1).map((blog) => (
          <div className="wedw8efe9c4ec9e4c w-100 d-flex justify-content-center">
            <div key={blog.id} className="center-blog">
              <div
                className="blog-imageprincipal"
                style={{
                  backgroundImage: blog.imagen,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "15px",
                  alignItems: "end",
                  display: "flex",
                }}
              ></div>
              <div className="blog-details">
                <h2 style={{ color: "#0a306a" }}>{blog.titulo}</h2>
                <p className="ny-tbrt84ve94h89ytb4v89er">
                  {blog.descripcion.slice(0, 300)}...
                </p>
                <Link
                  to={`/blog/detalle/${blog.id}`}
                  className="btn btn-ds98sd4f8d4-sd594csdc489d"
                >
                  Leer Más{" "}
                  <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
        {/* Renderizar la última noticia en una columna separada */}
        {blogsData.slice(0, -1).map((blog) => (
          <div key={blog.id} className="blog-card">
            <div
              className="blog-image"
              style={{
                backgroundImage: blog.imagen,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "15px 0px 15px 0px",
                alignItems: "end",
                display: "flex",
                width: "100%",
              }}
            ></div>
            <div className="blog-details">
              <h2 className="s-wd5we984fw9e84t98rb4v">{blog.titulo}</h2>
              <p className="ny-tbrt84ve94h89ytb4v89er">
                {blog.descripcion.slice(0, 100)}...
              </p>
              <Link
                to={`/blog/detalle/${blog.id}`}
                className="btn btn-ds98sd4f8d4-sd594csdc489d"
              >
                Leer Más{" "}
                <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
