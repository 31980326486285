import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../../store/appContext";
import { Link, useNavigate } from "react-router-dom";
import "../../../styles/UserHome.css";
import Chart from "chart.js/auto";
import ApexCharts from "react-apexcharts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const UserHome = () => {
  const [ingreso, setIngreso] = useState([]);
  const [egreso, setEgreso] = useState([]);
  const { actions, store } = useContext(Context);
  const navigate = useNavigate();
  const { token } = useContext(Context);
  const [userData, setUserData] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [usdRate, setUsdRate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [showPeople, setShowPeople] = useState(false);
  const [chartHeight, setChartHeight] = useState("3vh");
  const [chartWidth, setChartWidth] = useState("5vh");
  const [pointsData, setPointsData] = useState(null);

  const newsData = [
    {
      title: "¡Nueva actualización!",
      content:
        "Hemos lanzado una emocionante nueva actualización. ¡Descúbrelo ahora!",
    },
    {
      title: "Conéctate con nosotros",
      content:
        "Estamos emocionados de conectarnos contigo. Mantente actualizado con nuestras últimas noticias y características.",
    },
  ];

  const maxLines = 3;

  const areaChartOptions2 = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
      type: "category",
      categories: chartData.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      lines: Array.from({ length: maxLines }, (_, i) => ({
        value: i + 1, // Ajusta los valores de referencia según tus necesidades
        borderColor: "#ffffff",
        label: {
          borderColor: "#ffffff",
          style: {
            color: "#ffffff",
          },
          text: `Línea ${i + 1}`,
        },
      })),
    },
    colors: ["#151b26", "#151b26"],
    fill: {
      opacity: 0.7,
    },
    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
  };

  const areaChartOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
      type: "category",
      categories: chartData.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      lines: Array.from({ length: maxLines }, (_, i) => ({
        value: i + 1, // Ajusta los valores de referencia según tus necesidades
        borderColor: "#ffffff",
        label: {
          borderColor: "#ffffff",
          style: {
            color: "#ffffff",
          },
          text: `Línea ${i + 1}`,
        },
      })),
    },
    colors: ["#151b26", "#151b26"],
    fill: {
      opacity: 0.7,
    },
    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
  };

  const areaChartSeries = [
    {
      name: "Usuarios",
      data: chartData.map((data) => data.dailyPeople),
    },
  ];

  const areaChartSeries2 = [
    {
      name: "Autobuses",
      data: chartData.map((data) => data.dailyBuses),
    },
  ];

  const lineChartOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "category",
      categories: chartData.map((data) => data.date),
      labels: {
        style: {
          colors: "#ffffff", // Color blanco
        },
      },
    },
    yaxis: {
      title: {
        text: "",
        style: {
          color: "#ffffff", // Color blanco
        },
      },
      labels: {
        style: {
          colors: "#ffffff", // Color blanco
        },
      },
    },
    colors: ["#1D9D86", "#FF6384"],
    stroke: {
      curve: "smooth",
    },
    grid: {
      show: false, // Oculta las líneas de fondo
    },
    legend: {
      labels: {
        colors: "#ffffff", // Color blanco en la leyenda
      },
    },
  };

  const lineChartSeries = [
    {
      name: "Autobuses",
      data: chartData.map((data) => data.dailyBuses),
    },
    {
      name: "Usuarios",
      data: chartData.map((data) => data.dailyPeople),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: chartData.map((data) => data.date),
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    colors: ["#1D9D86", "#FF6384"], // Replace with your desired colors
  };

  const series = [
    {
      name: "Autobuses",
      data: chartData.map((data) => data.dailyBuses),
    },
    {
      name: "Usuarios",
      data: chartData.map((data) => data.dailyPeople),
    },
  ];

  useEffect(() => {
    obtenerTasaDeCambio();
    fetchMovements();
    const handleResize = () => {
      const isWideScreen = window.innerWidth <= 850;

      setChartHeight(isWideScreen ? "6vh" : "3vh");
      setChartWidth("5vh");
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const obtenerTasaDeCambio = async () => {
    try {
      const respuesta = await fetch(
        "https://ve.dolarapi.com/v1/dolares/oficial"
      );

      // Convertir la respuesta a JSON
      const datos = await respuesta.json(); // Aquí se agrega await para obtener los datos en formato JSON

      if (datos.promedio) {
        // Reemplazar los puntos por comas en el valor de la tasa
        const tasaFormateada = datos.promedio.toString().replace(/\./g, ","); // Asegúrate de convertir a string
        setUsdRate(tasaFormateada);
      } else {
        console.error(
          "La propiedad promedio no está presente en la respuesta del servidor."
        );
      }
    } catch (error) {
      console.error("Error al obtener la tasa de cambio:", error);
    }
  };

  // useEffect(() => {
  //   const fetchUserPoints = async () => {
  //     try {
  //       const options = {
  //         headers: {
  //           Authorization: `Bearer ${store.token}`,
  //         },
  //       };

  //       const response = await axios.get(
  //         `${process.env.BACKEND_URL}/api/verificar_puntos_usuario/${userData.email}`,
  //         options
  //       );
  //       setPointsData(response.data.puntos);
  //     } catch (error) {
  //       console.error("Error fetching user points", error);
  //     }
  //   };
  //   if (userData) {
  //     fetchUserPoints();
  //   }
  // }, [userData]);

  // const fetchUserData = async () => {
  //   const options = {
  //     headers: {
  //       Authorization: "Bearer " + store.token,
  //     },
  //   };
  //   try {
  //     const response = await axios.get(
  //       process.env.BACKEND_URL + "/api/protected",
  //       options
  //     );
  //     setUserData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching user data", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchUserData();
  // }, []);

  // useEffect(() => {
  //   actions.checkLogin(navigate);
  // }, []);

  const generateRandomData = () => {
    const currentDate = new Date();
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(currentDate.getDate() - (7 - i));

      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}`;
    });

    const data = last7Days.map((date) => ({
      date,
      dailyIncomes: getRandomNumber(1, 10),
      dailyExpenses: getRandomNumber(1, 10),
      // Nuevos datos ficticios para personas
      dailyPeople: getRandomNumber(100, 200),
      dailyBuses: getRandomNumber(100, 200),
    }));

    setChartData(data);
  };

  useEffect(() => {
    generateRandomData();
  }, [showPeople]);

  const formatDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const fetchMovements = async () => {
    // Ventana mientras se cargan los datos
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Datos de la página principal...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/GetTotalMonthEgresoIngreso",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      setIngreso(data.totales.total_ingresos);
      setEgreso(data.totales.total_egresos);

      MySwal.close();
    } catch (error) {
      console.error("Error al obtener Categorías", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener categorías. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ flexDirection: "column" }}
    >
      <div className="container suiahdisodm-we98f4wec">
        <div className="container d-flex p-0 s98d7fa1sd9c8a4sc9as4ca-s5ca4s89s">
          <div className="container asune-rv56er48v9wec-s">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s4">
                  <i className="fa-solid asd98asdas894xsa fa-couch"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Ingresos Mes</p>
                <h3 style={{ color: "green" }}>
                  {/* {pointsData !== null ? `${pointsData} pts` : "Muy Pronto..."} */}
                  {ingreso}
                </h3>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+52$</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}>
                  {" "}
                  Último ingreso
                </p>
              </div>
            </div>
          </div>
          <div className="container asune-rv56er48v9wec-s mgjmnh98-sdf9w8e7f9wewefwes">
            <div className="container aoshdnuqiwhnd897 ">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s42">
                  <i className="fa-solid asd98asdas894xsa fa-chart-simple"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Egresos Mes</p>
                <h3 style={{ color: "red" }}>{egreso}</h3>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+0%</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}> Este mes</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex as98d798qwd465qe-ew9f8qw8e9d s98d7fa1sd9c8a4sc9as4ca-s5ca4s89s">
          <div className="container asune-rv56er48v9wec-s ">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s43">
                  <i className="fa-solid asd98asdas894xsa fa-dollar-sign"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Dolar BCV</p>
                <h3 style={{ color: "black" }}>{usdRate} Bs</h3>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p
                  style={{ margin: "0", color: "red" }}
                  className="asd98as79d4sd98as4x"
                >
                  <strong>+0,2%</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}>Variación</p>
              </div>
            </div>
          </div>
          <div className="container asune-rv56er48v9wec-s mgjmnh98-sdf9w8e7f9wewefwes">
            <div className="container aoshdnuqiwhnd897">
              <div className="container ds9f848dsfwef498we4fwd-5798">
                <div className="sad8qw9d4asas6x4a89s44">
                  <i className="fa-solid asd98asdas894xsa fa-calendar-days"></i>
                </div>
              </div>
              <div className="container ds897fq4w9dc9w8c4-we89c7w">
                <p style={{ margin: "0" }}>Tus puntos</p>
                <h3 style={{ color: "black" }}>0</h3>
              </div>
            </div>
            <div className="asdw-w9d84c9w8e4dcd"></div>
            <div className="container aoshdnuqiwhnd8972">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ margin: "0" }} className="asd98as79d4sd98as4x">
                  <strong>+0%</strong>
                </p>
                <p style={{ margin: "0", marginLeft: "7px" }}> Muy Pronto</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container containerDeChartsUserHome">
        <div className="container asdasc987asc8a98dsc4sdv8e-v65sd4">
          <div className="asodhnoiuashnxiuasnx1">
            <ApexCharts
              options={areaChartOptions}
              series={areaChartSeries}
              type="area"
              height="100%"
              width="100%"
            />
          </div>

          <p className="letrasPequenasEnUserHomeDetalles">
            Últimos 7 días de Ingresos
          </p>
        </div>
        <div className="container asdasc987asc8a98dsc4sdv8e-v65sd42">
          <div className="asodhnoiuashnxiuasnx2">
            <ApexCharts
              options={areaChartOptions2}
              series={areaChartSeries2}
              type="area"
              height="100%"
              width="100%"
            />
          </div>
          <p className="letrasPequenasEnUserHomeDetalles">
            Últimos 7 días de Egresos
          </p>
        </div>
      </div>
      {/* <div className="container asdasc987asc8a98dsc4sdv8e-v65sd4">
        <ApexCharts
          options={options}
          series={series}
          type="bar"
          height="300"
          width="100%"
        />
        <p className="letrasPequenasEnUserHomeDetalles">
          Últimos 7 días de movimientos
        </p>
      </div> */}
      <div className="container containerDeChartsUserHome2">
        <div className="container ohasoidjqw-d48w9d8as7">
          <h5>Tus Últimos Movimientos (Ingresos y Egresos)</h5>
          <table className="table table-bordered">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Fecha</th>
                <th>Total Ingresos</th>
                <th>Total Egresos</th>
              </tr>
            </thead>
            <tbody>
              {chartData.map((data) => (
                <tr key={data.date}>
                  <td>{data.date}</td>
                  <td style={{ textAlign: "center" }}>{data.dailyBuses}</td>
                  <td style={{ textAlign: "center" }}>{data.dailyPeople}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="container ohasoidjqw-d48w9d8as72">
          <ul className="list-group">
            {newsData.map((news, index) => (
              <li key={index} className="list-group-item">
                <div className="accordion" id={`newsAccordion${index}`}>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                      >
                        {news.title}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent={`#newsAccordion${index}`}
                    >
                      <div className="accordion-body">{news.content}</div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className="container containerDechartHomejs">
        <canvas id="myChart" width={chartWidth} height={chartHeight}></canvas>
        <p className="letrasPequenasEnUserHomeDetalles">
          Últimos 7 días de movimientos
        </p>
      </div> */}
    </div>
  );
};
