// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.as54s98df7er98g7erw984cwe {
  height: 30vh;
  background-color: #d5cdc4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.as65d489f7889we7f89ewwe {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.s56d4a9s8d7we987vw98v7e894ewe {
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.sdf65s4d8f9s79f8w7e8ht4rwe {
  background-color: rgba(182, 182, 182, 0.186);
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0px 20px 0px;
  border-radius: 30px;
}
.sdferg4er98few498f4we {
  width: 30%;
  background-color: gray;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sdferg4er98few498f4we2 {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.sd564fsd98f79d8f798hh7te89rwe {
  color: white;
  font-size: 45px;
}
.d56fs4df98ew7fwemaps {
  border-radius: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/front/styles/contactanos.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb;IACE,sBAAsB;EACxB;AACF;AACA;EACE,UAAU;EACV;IACE,WAAW;EACb;AACF;AACA;EACE,4CAA4C;EAC5C,aAAa;EACb,6BAA6B;EAC7B,0BAA0B;EAC1B,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".as54s98df7er98g7erw984cwe {\n  height: 30vh;\n  background-color: #d5cdc4;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.as65d489f7889we7f89ewwe {\n  display: flex;\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n}\n.s56d4a9s8d7we987vw98v7e894ewe {\n  width: 50%;\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n.sdf65s4d8f9s79f8w7e8ht4rwe {\n  background-color: rgba(182, 182, 182, 0.186);\n  display: flex;\n  justify-content: space-evenly;\n  padding: 20px 0px 20px 0px;\n  border-radius: 30px;\n}\n.sdferg4er98few498f4we {\n  width: 30%;\n  background-color: gray;\n  height: 100px;\n  width: 100px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.sdferg4er98few498f4we2 {\n  width: 70%;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n.sd564fsd98f79d8f798hh7te89rwe {\n  color: white;\n  font-size: 45px;\n}\n.d56fs4df98ew7fwemaps {\n  border-radius: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
