import React, { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Context } from "../../../store/appContext";
import { createRoot } from "react-dom/client";

const MySwal = withReactContent(Swal);

const estatusColors = {
  ACTIVO: "#d4edda", // Verde claro
  ANULADO: "#f8d7da", // Rojo claro
  "EN PISTA": "#fff3cd", // Amarillo claro
  CARGANDO: "#d1ecf1", // Azul claro
  SALIENDO: "#fefefe", // Blanco
  SALIO: "#e2e3e5", // Gris claro
};

export const AutorizacionPorPuesto = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    empresa: "",
    rif: "",
    parada: "",
    ruta: "",
    destino_final: "",
    id_cps: null,
  });
  const [companies, setCompanies] = useState([]);
  const [cpsData, setCpsData] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [autorizaciones, setAutorizaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "empresa") {
      const selectedCompany = companies.find(
        (company) => company.company_name === value
      );
      setFormData((prevData) => ({
        ...prevData,
        rif: selectedCompany ? selectedCompany.company_rif : "",
        parada: "",
        ruta: "",
        destino_final: "",
        id_cps: null,
      }));
      setCpsData(selectedCompany ? selectedCompany.cps_data : []);
      setRutas([]);
    }

    if (name === "parada") {
      const filteredCps = cpsData.filter((cps) => cps.cps_parada === value);
      const uniqueRutas = [...new Set(filteredCps.map((cps) => cps.cps_route))];
      setRutas(uniqueRutas);
      setFormData((prevData) => ({
        ...prevData,
        ruta: "",
        destino_final: "",
        id_cps: null,
      }));
    }

    if (name === "ruta") {
      const selectedCps = cpsData.find((cps) => cps.cps_route === value);
      setFormData((prevData) => ({
        ...prevData,
        destino_final: selectedCps ? selectedCps.cps_final_destination : "",
        id_cps: selectedCps ? selectedCps.id : null,
      }));
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getAllCompaniesProgramadorRC",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;
      setCompanies(data);
      MySwal.close();
      selectCompanyDialog(data);
    } catch (error) {
      console.error("Error al obtener empresas registradas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener empresas registradas. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const selectCompany = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando empresas...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
        fetchCompanies();
      },
    });
  };

  const selectCompanyDialog = (companies) => {
    const companyOptions = companies.map((company, index) => ({
      id: index,
      text: `<strong>Nombre:</strong> ${company.company_name}<br/><strong>RIF:</strong> ${company.company_rif}`,
      value: company,
    }));

    MySwal.fire({
      title: "Seleccionar Empresa",
      html: `
        <input type="text" id="searchCompany" class="swal2-input" placeholder="Buscar Empresa">
        ${companyOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="company-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchCompany");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              empresa: companyOptions[id].value.company_name,
              rif: companyOptions[id].value.company_rif,
              parada: "",
              ruta: "",
              destino_final: "",
              id_cps: null,
            }));
            setCpsData(companyOptions[id].value.cps_data);
            setRutas([]);
          });
        });
      },
    });
  };

  const selectParada = async () => {
    const uniqueParadas = [...new Set(cpsData.map((cps) => cps.cps_parada))];
    const paradaOptions = uniqueParadas.map((parada, index) => ({
      id: index,
      text: parada,
      value: parada,
    }));

    await MySwal.fire({
      title: "Seleccionar Parada",
      html: `
        <input type="text" id="searchParada" class="swal2-input" placeholder="Buscar Parada">
        ${paradaOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="parada-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchParada");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            setFormData((prevData) => ({
              ...prevData,
              parada: paradaOptions[id].value,
              ruta: "",
              destino_final: "",
              id_cps: null,
            }));
            const filteredCps = cpsData.filter(
              (cps) => cps.cps_parada === paradaOptions[id].value
            );
            const uniqueRutas = [
              ...new Set(filteredCps.map((cps) => cps.cps_route)),
            ];
            setRutas(uniqueRutas);
          });
        });
      },
    });
  };

  const selectRuta = async () => {
    const rutaOptions = rutas.map((ruta, index) => ({
      id: index,
      text: ruta,
      value: ruta,
    }));

    await MySwal.fire({
      title: "Seleccionar Ruta",
      html: `
        <input type="text" id="searchRuta" class="swal2-input" placeholder="Buscar Ruta">
        ${rutaOptions
          .map(
            (option) =>
              `<div style="cursor: pointer; margin: 10px; padding: 10px; border: 1px solid #ccc;" data-id="${option.id}">${option.text}</div>`
          )
          .join("")}
        <div id="ruta-options"></div>
      `,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      didRender: () => {
        const searchInput = document.getElementById("searchRuta");
        searchInput.addEventListener("input", (event) => {
          const filter = event.target.value.toLowerCase();
          const elements = document.querySelectorAll("[data-id]");
          elements.forEach((element) => {
            const text = element.textContent.toLowerCase();
            element.style.display = text.includes(filter) ? "block" : "none";
          });
        });

        const elements = document.querySelectorAll("[data-id]");
        elements.forEach((element) => {
          element.addEventListener("click", () => {
            const id = element.getAttribute("data-id");
            MySwal.clickConfirm();
            const ruta = rutaOptions[id].value;
            setFormData((prevData) => ({
              ...prevData,
              ruta: ruta,
            }));
            const selectedCps = cpsData.find((cps) => cps.cps_route === ruta);
            setFormData((prevData) => ({
              ...prevData,
              destino_final: selectedCps.cps_final_destination,
              id_cps: selectedCps.id,
            }));
          });
        });
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.id_cps) {
      MySwal.fire({
        title: "Error",
        text: "Por favor, complete todos los campos requeridos.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    const existingAutorizacion = autorizaciones.find(
      (auth) => auth.id_cps === formData.id_cps
    );
    if (existingAutorizacion) {
      MySwal.fire({
        title: "Error",
        text: "Esta autorización ya existe.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      return;
    }

    setSubmitting(true);
    MySwal.fire({
      title: "Autorizando ruta para por puesto",
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerAutorizacionPorPuesto",
        { id_cps: formData.id_cps },
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        empresa: "",
        rif: "",
        parada: "",
        ruta: "",
        destino_final: "",
        id_cps: null,
      });

      MySwal.close();

      MySwal.fire({
        title: "Registro Exitoso",
        text: "La autorización ha sido registrada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      fetchAutorizaciones();
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: `${error.response.data.message}`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const fetchAutorizaciones = async () => {
    setLoading(true);
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando autorizaciones de hoy...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/getAutorizacionesPorPuesto",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;
      setAutorizaciones(data);

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Autorizaciones de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron autorizaciones.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        showAutorizacionesPopup(data);
      }
    } catch (error) {
      console.error("Error al obtener autorizaciones", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener autorizaciones. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setLoading(false);
    }
  };

  const showAutorizacionesPopup = (data) => {
    const content = data
      .map(
        (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc; background-color: #f9f9f9;">
            <p><strong>Empresa:</strong> ${item.empresa}</p>
            <p><strong>RIF:</strong> ${item.rif}</p>
            <p><strong>Parada:</strong> ${item.parada}</p>
            <p><strong>Ruta:</strong> ${item.ruta}</p>
            <p><strong>Destino Final:</strong> ${item.destino_final}</p>
            <button class="btn btn-danger" data-id="${item.id}" data-action="delete">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        `
      )
      .join("");

    MySwal.fire({
      title: "Autorizaciones por Puesto",
      html: `
        <div id="autorizaciones-list" style="overflow-y: auto; max-height: 70vh;">${content}</div>
      `,
      showConfirmButton: true,
      didRender: () => {
        document
          .querySelectorAll('button[data-action="delete"]')
          .forEach((button) =>
            button.addEventListener("click", (e) => {
              const id = e.currentTarget.getAttribute("data-id");
              confirmDeleteAutorizacion(id);
            })
          );
      },
    });
  };

  const confirmDeleteAutorizacion = (id) => {
    MySwal.fire({
      title: "¿Está seguro?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAutorizacion(id);
      }
    });
  };

  const deleteAutorizacion = async (id) => {
    try {
      await axios.delete(
        `${process.env.BACKEND_URL}/api/deleteAutorizacionPorPuesto/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      MySwal.fire({
        title: "Autorización Eliminada",
        text: "La autorización ha sido eliminada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
      fetchAutorizaciones();
    } catch (error) {
      console.error("Error al eliminar la autorización", error);
      MySwal.fire({
        title: "Error",
        text: "Error al eliminar la autorización. Por favor, inténtelo de nuevo más tarde.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingTop: "30px" }}
    >
      <div className="container-fluid contaosijdasd-qw89d77asd223"></div>
      <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Autorización Por Puesto
      </h2>
      <div className="container asklnduiashndaijsnxansxsaas">
        <form
          className="d-flex sd98f4e8wf4cwe987cwe984cwe"
          onSubmit={handleSubmit}
        >
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={formData.empresa}
                readOnly
                required
                onClick={selectCompany}
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="rif"
                value={formData.rif}
                readOnly
                required
              />
              <label>RIF</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="parada"
                value={formData.parada}
                readOnly
                required
                onClick={selectParada}
              />
              <label>Parada</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="text"
                name="ruta"
                value={formData.ruta}
                readOnly
                required
                onClick={selectRuta}
              />
              <label>Ruta</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destino_final"
                value={formData.destino_final}
                readOnly
                required
              />
              <label>Destino Final</label>
            </div>
          </div>

          <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98f4v895y4n9r84bvr98ebtnbtn">
            <button
              type="submit"
              className="btn buttonCargadeDatosDeAddDriver"
              style={{ marginBottom: "30px" }}
              disabled={submitting}
            >
              <div className="d-flex">
                <p style={{ marginBottom: 0 }}>Guardar Datos</p>
                <i className="fa-solid fa-arrow-right flechaHomePresentation2"></i>
              </div>
            </button>
          </div>
        </form>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98f4v895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="btn asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchAutorizaciones}
            disabled={loading}
          >
            <i
              className="fa-solid fa-route"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Autorizaciones
          </button>
        </div>
      </div>
    </div>
  );
};
