import React, { useContext, useEffect, useState, useRef } from "react";
import "../../styles/home4sup.css";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Link } from "react-router-dom";
import "../../styles/home4sup.css";
import "../../styles/home3.css";
import "../../styles/BlogNoticias.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Helmet } from "react-helmet-async";

const MySwal = withReactContent(Swal);

export const Sashatech = () => {
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: useDeviceSize(),
      spacing: 15,
      origin: "center",
    },
    mode: "free-snap",
    duration: 0.5,
  });
  const [formData, setFormData] = useState({
    name: "",
    empresa: "",
    email: "",
    descripcion: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Gracias por compartir tu idea con nosotros!</p>,
      html: (
        <p>Te enviaremos un correo electrónico muy pronto con una respuesta</p>
      ),
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Algo sal mal, por favor vuelve a intentarlo</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Enviar el correo al backend
      await axios.post(process.env.BACKEND_URL + "/api/contacto", formData);

      mostrarAlerta1();
      // Limpiar el formulario después de enviar los datos
      setFormData({
        name: "",
        empresa: "",
        email: "",
        descripcion: "",
      });
    } catch (error) {
      mostrarAlerta2();
    }
  };

  function useDeviceSize() {
    const [sizeValue, setSizeValue] = useState(getSizeValue());

    function getSizeValue() {
      const width = window.innerWidth;
      if (width < 768) {
        // Suposición para teléfonos
        return 2;
      } else if (width >= 768 && width < 1024) {
        // Suposición para tablets
        return 4.5;
      } else {
        // Suposición para computadores
        return 6.5;
      }
    }

    useEffect(() => {
      function handleResize() {
        setSizeValue(getSizeValue());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return sizeValue;
  }
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef2, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const blogsData = [
    {
      id: 2,
      titulo: "10 Cosas que no sabías sobre el SEO",
      descripcion:
        "El SEO va más allá de las palabras clave. Descubre 10 curiosidades que no conocías sobre el posicionamiento en buscadores y cómo puede impactar en la visibilidad de tu negocio online.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728141844/SashaTech/zyi8angmqgkazkyw6d9a.jpg')",
    },
    {
      id: 4,
      titulo: "Por qué tu empresa necesita una presencia en la nube",
      descripcion:
        "Migrar a la nube ya no es una opción, es una necesidad. Te contamos cómo implementar soluciones en la nube puede optimizar el rendimiento y la eficiencia de tu negocio.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728142860/SashaTech/pwin8xarsx8indfjztes.png')",
    },
    {
      id: 5,
      titulo: "10 Errores comunes en el SEO que debes evitar",
      descripcion:
        "Muchos negocios cometen los mismos errores cuando intentan mejorar su SEO. Te explicamos los 10 errores más comunes y cómo evitarlos para que tu página logre un mejor ranking.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728143011/SashaTech/du9m8nd3j28dfgkvovvl.jpg')",
    },
    {
      id: 1,
      titulo: "¿Quién es SASHA TECH y cómo puede ayudarte?",
      descripcion:
        "SASHA TECH es una empresa de marketing digital y soluciones tecnológicas con sede en España y Venezuela. Ofrecemos servicios de posicionamiento SEO, desarrollo web y sistemas en la nube. Descubre cómo podemos ayudarte a mejorar tu presencia en línea y optimizar tus procesos.",
      imagen:
        "url('https://res.cloudinary.com/dirpdlfbu/image/upload/v1728129163/SashaTech/itlpjnnksxpp6tyvj4bb.png')",
    },
  ];

  return (
    <div className="as-dq9s54da9sq-sd5qs-dasxasx51q-sd5asd">
      <Helmet>
        <title>Sasha Tech - Diseñador Web en Caracas</title>
        <meta
          name="description"
          content="Sasha Tech ofrece servicios de marketing digital, SEO y desarrollo web para empresas en Venezuela y España. Impulsa tu negocio con soluciones innovadoras."
        />
        <link rel="canonical" href="https://www.sashatech.com/" />
        <meta
          property="og:title"
          content="Sasha Tech - Diseñador Web en Caracas"
        />
        <link
          rel="sitemap"
          type="application/xml"
          title="Sitemap"
          href="/sitemap.xml"
        />

        <meta
          property="og:description"
          content="Contáctanos para servicios de marketing digital, SEO, y desarrollo web."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dirpdlfbu/image/upload/v1728129163/SashaTech/itlpjnnksxpp6tyvj4bb.png"
        />
        <link rel="icon" href="ellipse.png" type="image/x-icon" />
        <meta property="og:url" content="https://sashatechve.com" />
        <meta property="og:type" content="website" />
        <meta name="theme-color" content="#041A22" />
      </Helmet>
      <>
        <div className="navigation-wrapper" style={{ position: "relative" }}>
          <div
            ref={sliderRef2}
            className="keen-slider as65d489we7g897yuty9n8bev7wwexs"
            style={{ height: "83vh" }}
          >
            <div className="keen-slider__slide number-slide1 as67et987hr98th7ber98v4ewr98f7we98d4te98r">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h6 className="as56d4w8e9f7eruy8j7hrsh1">
                    Controla tu Negocio
                  </h6>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Imagina llevar al fin el control de tu negocio desde una
                    sola plataforma, ahora con Sasha Tech, el cual te ofrece un
                    sistema totalmente propio para ti para llevar el control de
                    tu Negocio
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger"></div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide number-slide2">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h1 className="as56d4w8e9f7eruy8j7hrsh1">
                    Tendrás tu Página Web!
                  </h1>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Y tu sistema, todo en un solo sitio, recibe pedidos, control
                    de tus finanzas, registro de usuarios para poder comunicarte
                    con ellos y ofrecerles un mejor servicio
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger2"></div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide number-slide3">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h1 className="as56d4w8e9f7eruy8j7hrsh1">
                    Todo en Tiempo Real
                  </h1>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Y en tiempo record, pide tu sesión privada con nosotros, y
                    habla sobre tu negocio, espectativas y nuestro Director SEO
                    Alexander, te dará las mejores recomendaciones
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger3"></div>
                </div>
              </div>
            </div>
            <div className="keen-slider__slide number-slide4">
              <div className="container d-flex d6g489yj7yu987ko9u8iu7tytrre98ew7f98hm4yntrwew98dr">
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <h1 className="as56d4w8e9f7eruy8j7hrsh1">
                    Control Y Crecimiento
                  </h1>
                  <p className="s5d64f987tythre8wfqe8gtyuras54dtext">
                    Nuestro Director SEO, Alexander, nos dice: "Si conoces con
                    precisión tus ingresos y egresos, podrás tener un mejor
                    control de tu negocio."
                  </p>
                </div>
                <div className="container sd6f48ew9f7et98b7t9b8r4t89er4">
                  <div className="d56f4sdg987t89rt9b8rt7ger4"></div>
                </div>
              </div>
            </div>
          </div>
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            })}
          </div>
        )}
      </>

      <div className="sdf564rt98h7r987trcsa">
        <div className="container a5s6d5f4g89y7th98wrf4w9e84trhwf48e">
          <div className="sd654dr98gfw7e897r89t7gerfwe">
            <h1 style={{ color: "white" }}>Que nos hace Diferentes</h1>
            <p
              style={{ color: "white" }}
              className="s5d64f987tythre8wfqe8gtyuras54dtext2"
            >
              Además de haber haber escogido, meticulosamente las mejores
              prácticas a nivel internacional por nuestra experiencia en el
              extranjero en países muy desarrollados, hemos ingeniado la forma
              de ofrecerle a las empresas un todo en uno
            </p>
          </div>
          <div className="sd654dr98gfw7e897r89t7gerfwe2">
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>Contamos con la Experiencia</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Mejoramos tus números en el mercado local
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>Totalmente Propio</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  El Sistema es totalmente del Cliente
                </p>
              </div>
            </div>
            <div className="sd654fs89dgv48d9rgvw89ece">
              <div>
                <h4 style={{ color: "white" }}>Crearás tus usuarios</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Crea usuarios asigna tareas, y personaliza tu Sistema
                </p>
              </div>
              <div>
                <h4 style={{ color: "white" }}>Unifica y potencia</h4>
                <p
                  style={{ color: "white" }}
                  className="s5d64f987tythre8wfqe8gtyuras54dtext3"
                >
                  Automatiza, Controla, Potencia y Sobre todo Disfruta del
                  proceso
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-align-center sd6f498et7rrg98ewf4w98e4fwe">
        <h1
          style={{ paddingBottom: "20px", textAlign: "center", color: "black" }}
        >
          Encuentra tu Proyecto Adecuado
        </h1>
        <div style={{ maxWidth: "750px" }}>
          <p style={{ textAlign: "center", color: "black" }}>
            Ofrecemos variedad de planes ajustados al nivel de tu negocio, en
            todos nuestros planes incluímos un canal de comunicación activo las
            24/7, especial para todos nuestros clientes, siente Seguridad y
            confianza con Nosotros
          </p>
        </div>
        <div className="pasd56s4df89we7f98wef48egwef">
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we m-0">
            <h4>Plan Básico</h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Ideal para pequeñas empresas que necesitan una presencia en línea
              que se ajuste a su presupuesto y que cumpla sus espectativas
            </p>
            <p className="m-0">Planes desde</p>
            <h2>Consultar</h2>
            <p>/mes</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Mantenimiento Esencial
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Soporte 24/7
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Monitoreo de Seguridad
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Página Web con Posicionamiento SEO
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/contactanos"
                className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s2"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we2">
            <div className="sddfg56e4r8g9w7e8f9q4wd8qw7">
              <p className="sd654sd8f9g7dsf98g7af9w8e4 m-0">Más Solicitado</p>
            </div>
            <h4 style={{ marginTop: "10px" }}>Plan Profesional</h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Perfecto para negocios en constante crecimiento que requieren
              optimización y soporte avanzado, por ende mayor cantidad de
              visitas
            </p>
            <p className="m-0">Planes Desde</p>
            <h2>Consultar</h2>
            <p>/mes</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Actualizaciones regulares al sistema
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Soporte 24/7
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Optimización SEO y rendimiento
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Monitoreo de Seguridad y backups periódicos
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/contactanos"
                className="btn a-sd4q98dq-sdas98d4as-btn22 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s22"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we">
            <h4>Plan Avanzado</h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Para empresas que empiezan a necesitar llevar un mejor control de
              sus ingresos, egresos, seguimiento online, estadísticas y mejorar
              su presencia digital
            </p>
            <p className="m-0">Planes desde</p>
            <h2>Consultar</h2>
            <p>/mes</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Mantenimiento Completo
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Soporte 24/7 a cualquier petición
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Optimización avanzada de SEO y rendimiento
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Sistema con Análisis y reportes detallados
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/contactanos"
                className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s2"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
          <div className="sd54de65df48e9rg7rt98b498ewf4we98d7we">
            <h4>Plan Corporativo</h4>
            <p className="dfgdfger6gd4fge4r94erger9ge7r">
              Solución Personalizada para grandes empresas con necesidades
              específicas
            </p>
            <p className="m-0">Planes desde</p>
            <h2>Consultar</h2>
            <p>/mes</p>
            <div className="sd65f48th97er89g7we89f7qwe98fwe">
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Gestión total y personalizada
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Soporte 24/7 a cualquier petición
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Estrategias avanzadas de SEO y marketing
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
              <div className="m1jmn4gfv4w98ef78e9r7g">
                <i className="fa-solid fa-check s5d64fs89df7sd98f7sd me-3"></i>
                <p className="m-0 sd5f4fg4gf9h879d8f7e98yu798re">
                  Personaliza tus opciones
                </p>
              </div>
              <div className="ghner87ry98h7er89v4we897cqw"></div>
            </div>
            <li className="nav-item sd65f489r4grt8h4er984g98ew4fqw">
              <Link
                to="/contactanos"
                className="btn a-sd4q98dq-sdas98d4as-btn222 mb-0 d-flex w-100"
                style={{ minWidth: "0" }}
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fa-solid fa-info as-da4s9da8s4d9qd5a6s2"
                ></i>
                <span>Más Información</span>
              </Link>
            </li>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center w-100 sdfs4df9e4g98r4t8j9tyb9erf48w9e7fd9qe4we8">
        <div
          className="d-flex justify-content-center w-100"
          style={{
            flexDirection: "column",
            alignContent: "center",
            width: "100%",
            alignItems: "center",
            marginBottom: "70px",
            padding: "10px",
          }}
        >
          <div className="tittle-80 w-100" style={{ maxWidth: "900px" }}>
            <h2
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "70px",
              }}
            >
              ¿Hablamos?, Cuentanos sobre tu proyecto
            </h2>
            <p
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "40px",
              }}
            >
              En Sasha Tech, estamos encantados de conocer más sobre ti y tu
              proyecto. Antes de que nos contactes, nos gustaría que nos cuentes
              un poco sobre lo que tienes en mente para poder ofrecerte la mejor
              solución posible. Para hacerlo más fácil y directo, hemos
              preparado el siguiente mini formulario para que puedas compartir
              los detalles más importantes con nosotros.
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin s6d54fsd89f7sd98f4we98f7wr89 w-100"
          >
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
              />
              <label htmlFor="name" style={{ backgroundColor: "#F6F6F4" }}>
                Nombre
              </label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" style={{ backgroundColor: "#F6F6F4" }}>
                Correo electrónico
              </label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={formData.empresa}
                onChange={handleChange}
                required
              />
              <label htmlFor="empresa" style={{ backgroundColor: "#F6F6F4" }}>
                Empresa
              </label>
            </div>
            <div className="input-group-login2">
              <textarea
                className="inputDeLogin"
                type="text"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                required
                style={{ minHeight: "200px" }}
              />
              <label
                htmlFor="descripcion"
                style={{ backgroundColor: "#F6F6F4" }}
              >
                Describe brevemente de que se trata el proyecto...
              </label>
            </div>
            <button className="buttonCargadeDatosDeLogin" type="submit">
              Enviar Datos <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
      </div>
      <div className="container d-flex blogs-container mt-5 mb-6">
        {/* Última noticia en una columna separada */}
        {blogsData.slice(-1).map((blog) => (
          <div className="wedw8efe9c4ec9e4c w-100 d-flex justify-content-center">
            <div key={blog.id} className="center-blog">
              <div
                className="blog-imageprincipal"
                style={{
                  backgroundImage: blog.imagen,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "15px",
                  alignItems: "end",
                  display: "flex",
                }}
              >
                {/* <div className="asijmv-x59z4e">{blog.fecha}</div> */}
              </div>
              <div className="blog-details">
                <h2 style={{ color: "black" }}>{blog.titulo}</h2>
                <p className="ny-tbrt84ve94h89ytb4v89er">
                  {blog.descripcion.slice(0, 300)}...
                </p>
                <Link
                  to={`/blog/detalle/${blog.id}`}
                  className="btn btn-ds98sd4f8d4-sd594csdc489d"
                >
                  Leer Más{" "}
                  <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
        {/* Renderizar la última noticia en una columna separada */}
        {blogsData.slice(0, -1).map((blog) => (
          <div key={blog.id} className="blog-card">
            <div
              className="blog-image"
              style={{
                backgroundImage: blog.imagen,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "15px 0px 15px 0px",
                alignItems: "end",
                display: "flex",
                width: "100%",
              }}
            >
              {/* <div className="asijmv-x59z4e2">{blog.fecha}</div> */}
            </div>
            <div className="blog-details">
              <h2 className="s-wd5we984fw9e84t98rb4v">{blog.titulo}</h2>
              <p className="ny-tbrt84ve94h89ytb4v89er">
                {blog.descripcion.slice(0, 100)}...
              </p>
              <Link
                to={`/blog/detalle/${blog.id}`}
                className="btn btn-ds98sd4f8d4-sd594csdc489d"
              >
                Leer Más{" "}
                <i className="fa-solid d-4ev-e48v9s4cs98d4csdcs fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="sd4f5sd65f4er89h7er89gwe8f4we">
        <div className="container sd4sdf654g8r9th7t98ybh9er84er98ge9r84we98fwe">
          <div className="sdf56s4df89er798erg49er87f98rsd1884">
            <h1>Deja tu Correo!</h1>
            <p>
              Para enterarte de todos los proyectos que vamos haciendo, y lo que
              vamos logrando!
            </p>
          </div>
          <div className="sdf56s4df89er798erg49er87f98rsd18842">
            <form
            // onSubmit={mostrarAlerta2}
            >
              <div className="input-group-login bg-light">
                <input
                  className="inputDeLogin"
                  type="email"
                  name="email"
                  // value={formData.email}
                  // onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="email">Correo Electrónico</label>
              </div>
              <button
                // type="submit"
                // onClick={mostrarAlerta2()}
                className="btn btn-light button-suscribirse"
              >
                Suscribirse
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}
