import React, { useState, useContext } from "react";
import "../../../../styles/MovimientosDeUsuarios.css";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Mensajes = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    fecha: "",
    valor: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerpreciodolar",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        fecha: "",
        valor: "",
      });

      MySwal.fire({
        title: "Valor del dolar Registrado",
        text: "El Valor ha sido registrado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el valor del dolar.";

      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchdolar = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando datos registrados...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/precioDolarDiariotoday",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Valor Dolar Registrados",
          html: `<p style="text-align:center; margin:20px;">No se encontraron datos.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const content = data
          .map(
            (item) => `
          <div style="margin: 10px; padding: 10px; border: 1px solid #ccc;">
          <p><strong>Fecha y hora de Registro:</strong> ${item.fecha_hora_registro}</p>
            <p><strong>Fecha de Aplicación:</strong> ${item.fecha_aplica_dolar}</p>
            <p><strong>Valor Bs:</strong> ${item.valor}</p>
          </div>
        `
          )
          .join("");

        MySwal.fire({
          title: "Valor Dolar Registrados",
          html: `
            <div id="companies-list">${content}</div>
          `,
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener valores registrados", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener valores registrados. Por favor, inténtelo de nuevo más tarde.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="container-msg">
        <div className="container-msg2">
          <div className="container-msg2-1">
            <h1
              style={{
                color: "black",
              }}
            >
              Chats
            </h1>
          </div>
          <div className="container-msg2-2">
            {/* Otros Usuarios y Soporte */}
          </div>
          <div className="container-msg-general">
            <div className="container-msg-general-chat">
              <div className="container-msg-general-chat2">
                <div className="circle-photo-user-msg"></div>
              </div>
              <div className="container-msg-general-chat3">
                <h4>Name and Last Name</h4>
                <p>Message...............</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-msg3">
          <div className="nav-container-msg"></div>
          <div className="container-msg-general-msg">
            <div className="subcontainer-msg-general-msg"></div>
          </div>
          <div className="write-msg"></div>
        </div>
      </div>
    </div>
  );
};
