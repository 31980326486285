import React, { Component } from "react";
import "../../styles/footer.css";
import { Link } from "react-router-dom";

export const Footer = () => (
  <footer className="footer">
    {/* <div>
      <div
        className="circle-98765"
        style={{ marginTop: "100px", marginLeft: "25vw" }}
      ></div>
      <div
        className="circle-987652"
        style={{ marginTop: "200px", marginLeft: "15vw" }}
      ></div>
      <div
        className="circle-987651"
        style={{ marginTop: "150px", marginLeft: "40vw" }}
      ></div>
      <div
        className="circle-9876512"
        style={{ marginTop: "200px", marginLeft: "40px" }}
      ></div>
      <div
        className="circle-9876513"
        style={{ marginTop: "70px", marginLeft: "30vw" }}
      ></div>
      <div
        className="circle-9876514"
        style={{ marginTop: "70px", marginLeft: "5px" }}
      ></div>
    </div> */}
    <div className="container zpasascnas">
      <div className="pqowjdqwnds">
        <div>
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1722184710/1/SASHA%20TECH/uer8zc1spuqpoax4k4xj.png"
            className="img-897985124"
            alt="Logo MiTicketDigital"
          />
          <div style={{ marginTop: "30px" }}>
            <div className="spdfsmdfsnd">
              <div className="circ-87915165489">
                <i className="fa-brands fa-whatsapp"></i>
              </div>
              <h6
                className="dqyujt-ynb5ervwecqw56wec"
                style={{ marginTop: "8px" }}
              >
                +58 4242086513 / +34 624443813
              </h6>
            </div>
            <div className="spdfsmdfsnd">
              <div className="circ-87915165489">
                <i className="fa-regular fa-envelope"></i>
              </div>
              <h6
                className="dqyujt-ynb5ervwecqw56wec"
                style={{ marginTop: "8px" }}
              >
                contacto@sashatechve.com
              </h6>
            </div>
            <div className="spdfsmdfsnd">
              <div className="circ-87915165489">
                <img
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1722188215/1/SASHA%20TECH/ywe8q8wmt03uvqxeibpg.png"
                  style={{ width: "40px" }}
                />
              </div>
              <h6
                className="dqyujt-ynb5ervwecqw56wec"
                style={{ marginTop: "8px" }}
              >
                Venezuela
              </h6>
            </div>
            <div className="spdfsmdfsnd">
              <div className="circ-87915165489">
                <img
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1728141758/icons/va3zxnvnsbarih7i07xv.png"
                  style={{ width: "40px" }}
                />
              </div>
              <h6
                className="dqyujt-ynb5ervwecqw56wec"
                style={{ marginTop: "8px" }}
              >
                España
              </h6>
            </div>
          </div>
        </div>
        <div style={{ width: "calc(320px)", marginTop: "32px" }}>
          <div className="apsidhqwbdcsaaa">
            <div className="line-aspdkasj"></div>
            <h6 className="dqyujt-ynb5ervwecqw56wec" style={{ margin: "0" }}>
              INVIERTE JOVEN
            </h6>
          </div>
          <div style={{ marginTop: "30px" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Nuestros Proyectos</h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Casos de Éxito</h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">
                Visualiza los Avances en Tiempo Real
              </h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">
                Simplifica y Aumenta tus Ingresos
              </h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Compromiso con la Seguridad</h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">
                Experiencia del Usuario Personalizada
              </h6>
            </Link>
          </div>
        </div>
        <div style={{ width: "calc(320px)", marginTop: "32px" }}>
          <div className="apsidhqwbdcsaaa">
            <div className="line-aspdkasj"></div>
            <h6 className="dqyujt-ynb5ervwecqw56wec" style={{ margin: "0" }}>
              SOBRE NOSOTROS
            </h6>
          </div>
          <div style={{ marginTop: "30px" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Sobre Nosotros</h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Reserva tu llamada</h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Porque somos tu mejor opción</h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Nuestros clientes</h6>
            </Link>
            <Link to="/" style={{ textDecoration: "none" }}>
              <h6 className="pasiqwbdaszzz">Términos y condiciones</h6>
            </Link>
          </div>
        </div>
      </div>
      <div
        className="container ajksdnaskldmaslkdas"
        style={{ marginTop: "5vh" }}
      >
        <Link to="/AvisoLegal" className="asjdnasjdnasdnqowpjiddas">
          <p>Aviso Legal</p>
        </Link>
        <Link to="/Politicadeprivacidad" className="asjdnasjdnasdnqowpjiddas">
          <p>Políticas de Privacidad</p>
        </Link>
        <Link to="/Politicadecookies" className="asjdnasjdnasdnqowpjiddas">
          <p>Política de Cookies</p>
        </Link>
      </div>
      <div className="pqowjdqwnds" style={{ marginTop: "5vh" }}>
        <div></div>
        <div>
          <p className="oasinoda987asd">
            SASHA TECH C.A., Todos los derechos reservados.
          </p>
        </div>
        <div></div>
      </div>
    </div>
  </footer>
);
